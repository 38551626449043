import React from "react"
import PageLayout from "../common/PageLayout"
import styled from "styled-components"
import Feature from "../main/Feature"
import { URL } from "../common/constants"
import { ReactComponent as TeamImage } from "../images/teamvaloy.svg"
import { ReactComponent as TeamBg } from "../images/team-bg.svg"

export default function About() {
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  return (
    <PageLayout>
      <Container>
        <header>
          <div id="background" />
          <div id="header__content">
            <p>About Us</p>
            <h1>
              마음만 준비하세요,
              <br />
              표현은 뭐라고할까에서
            </h1>
            <p>
              업무 N년차, 깜빡이는 커서 앞에선 여전히 고민된다면?
              <br />
              뭔가 쓰긴 썼는데 전송 버튼 누르기를 망설인다면!
              <br />
              뭐라고할까는 당신을 위한 서비스예요.
              <br />
              최소한의 노력으로 내게 꼭 맞는 템플릿을 가져가세요.
            </p>
          </div>
        </header>
        <section>
          <Feature />
          <Team>
            <div>
              <TeamBg />
              <TeamImage />
            </div>
            <div>
              <p>
                뭐라고할까는 아이디어를 나누고 구현하는 것을 즐기는
                <br />
                디자이너와 개발자가 함께 운영 중이에요.
                <br />
                가볍게 시작한 서비스에 입소문으로 많은 분들이 찾아와 응원과
                피드백을 주며 이용해 주신 덕분에 여기까지 이어져 왔답니다!
              </p>
              <a href={URL.QNA} target="_blank" rel="noopener noreferrer">
                여전히 우리가 궁금한가요?
              </a>
            </div>
          </Team>
        </section>
      </Container>
    </PageLayout>
  )
}

const Container = styled.div`
  min-height: 100vh;

  header {
    position: relative;
    height: 508px;
    overflow: hidden;

    #background {
      z-index: -1;
      width: 200vw;
      height: 200vw;
      margin-left: -50%;
      box-sizing: border-box;
      margin-top: calc((200vw - 508px) * -1);
      background: #5551ff;
      border-radius: 100%;
      @media (max-width: 800px) {
      }
    }

    #header__content {
      position: absolute;
      top: 155px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      color: white;
      p:first-of-type {
        display: inline-block;
        font-family: "Space Grotesk", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 8px;
        background: linear-gradient(
          274deg,
          #7ecde8 13.87%,
          #c1dcd9 58.14%,
          #8c77f4 97.43%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      h1 {
        display: block;
        margin-bottom: 40px;
        font-family: "Giants";
        font-size: 40px;
        line-height: 120%;
      }
      p:last-of-type {
        line-height: 150%;
      }
    }
    @media (max-width: 800px) {
      height: 400px;
      #background {
        margin-top: calc((200vw - 400px) * -1);
      }
      #header__content {
        top: 100px;
        width: 100%;
        padding: 0 24px;
        p:first-of-type {
          font-size: 16px;
        }
        h1 {
          font-size: 24px;
          margin-bottom: 32px;
        }
        p:last-of-type {
          font-size: 14px;
        }
      }
    }
  }

  section {
    text-align: center;
  }
`

const Team = styled.div`
  max-width: 424px;
  margin: 200px auto 120px;
  text-align: center;
  > :first-child {
    width: 424px;
    height: 132.2px;
    position: relative;
    svg:first-of-type {
      position: absolute;
      left: -24px;
      top: -60px;
    }
    svg:last-of-type {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  > :last-child {
    text-align: center;
    max-width: 484px;
    p {
      margin: 56px 0 24px;
      color: #383e57;
      font-family: Pretendard;
      font-size: 16px;
      line-height: 140%; /* 25.2px */
      word-break: keep-all;
      font-family: "BM-HANNA";
    }
    a {
      display: block;
      height: 48px;
      line-height: 48px;
      width: 100%;
      text-align: center;
      border-radius: 30px;
      border: 1px solid #5551ff;
      color: #5551ff;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      line-height: 47px;
    }
  }

  @media (max-width: 800px) {
    width: 87%;
    margin: 152px auto 80px;
    > * {
      max-width: 100%;
    }
    > :first-child {
      svg:first-of-type {
        left: 50%;
        transform: translateX(-50%);
        top: -124px;
      }
    }
    > :last-child {
      p {
        text-align: center;
        font-size: 14px;
      }
    }
  }
`
