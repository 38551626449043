import React, { useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import { ReactComponent as MoreIcon } from "../images/moredots.svg"

function MoreMenus({ children, className, dotsSize = 24 }) {
  const [showMenus, setShowMenus] = useState(false)

  const clickListener = () => {
    if (showMenus) {
      setShowMenus(false)
      document.removeEventListener("click", clickListener)
    }
  }
  useEffect(() => {
    return () => {
      document.removeEventListener("click", clickListener)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (showMenus) {
      document.addEventListener("click", clickListener)
    }
  }, [showMenus]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container
      onClick={() => {
        setShowMenus(!showMenus)
      }}
      className={className}
    >
      {children.props.children?.length > 1 ? (
        <DropdownMenus className={showMenus ? "clickable slide-down" : ""}>
          {children}
        </DropdownMenus>
      ) : (
        <Menus className={showMenus ? "clickable slide-down" : ""}>
          {children}
        </Menus>
      )}
      <MoreIcon
        id="more-icon"
        className={showMenus ? "rotate clickable" : "clickable"}
        width={dotsSize}
        height={dotsSize}
      />
    </Container>
  )
}

const slideDown = keyframes`
  0% {
    transform: translateY(-1em);
    opacity: 0;
  }
  50% {
    transform: translateY(-.5em);
    opacity: .5;
  }
  100% {
    transform: translateY(0em);
    opacity: 1;
    pointer-events: unset;
  }
`

const Container = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  position: relative;
  #more-icon {
    display: inline-block;
    transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .slide-down {
    animation: ${slideDown} 0.2s linear forwards;
  }
  .rotate {
    transform: rotate(-90deg);
  }
`

const Menus = styled.div`
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  > * {
    display: inline-block;
    margin-right: 4px;
  }
`

const DropdownMenus = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 24px;
  width: 90px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`

export default MoreMenus
