import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { showLoginPopup } from "../../store/slices/layerSlice"
import styled from "styled-components"
import { db, fieldValue } from "../../common/firebase.utils"
import { ReactComponent as WarnIcon } from "../../images/warn.svg"
import { ReactComponent as EditIcon } from "../../images/edit.svg"
import { ReactComponent as TrashIcon } from "../../images/delete.svg"
import ToListButton from "../ToListButton"
import ReactionIcon from "./ReactionIcon"
import MoreMenus from "../../common/MoreMenus"
import Comment from "./Comment"
import CommentWrite from "./CommentWrite"
import CommentEmpty from "./CommentEmpty"
import ProfileAndTime from "./ProfileAndTime"
import { ORDER, ORDER_BY } from "../../common/constants"
import { addViewCount } from "../../common/utils"
import {
  getMyReaction,
  reactToPost,
  changeReactionType,
  cancelReactionByRef
} from "../../common/reactionUtil"
import {
  REPORT_HIDE_COUNT,
  COMMUNITY_REACTIONS,
  COMMUNITY_CATEGORIES
} from "../../common/constants"
import { useUserInfo } from "../../hooks/useUserInfo"

function Detail({ categoryId, postId, goToList }) {
  const [post, setPost] = useState({})
  const [commentsMap, setCommentsMap] = useState({})
  const [myReaction, setMyReaction] = useState(null)
  const mainCommentsRef = useRef({})
  const commentsMapRef = useRef(commentsMap)
  const requestingRef = useRef(false)

  const { userId } = useUserInfo()
  const isMine = post.writerid === userId

  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  useEffect(() => {
    db.collection("posts")
      .doc(postId)
      .get()
      .then(doc => {
        const data = doc.data()
        if (data.deletedat) {
          window.alert("삭제된 글입니다.")
        } else if (data.reportCount >= REPORT_HIDE_COUNT) {
          window.alert(`${REPORT_HIDE_COUNT}회 이상 신고되어 숨겨진 글입니다.`)
          history.replace(`/community/${categoryId}`)
        } else {
          setPost(data)
          addViewCount("posts", postId)
        }
      })
      .catch(e => {
        window.alert("포스트를 불러오는 데 실패했습니다.")
      })
  }, [postId])

  useEffect(() => {
    let unsubscribe
    if (post.title) {
      unsubscribe = subscribeComments()
      if (userId) {
        updateMyReaction()
      }
    }
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [post])

  useEffect(() => {
    commentsMapRef.current = commentsMap
  }, [commentsMap])

  const updateMyReaction = async () => {
    const myReaction = await getMyReaction(userId, "posts", postId)
    setMyReaction(myReaction)
  }

  const subscribeComments = () => {
    return db
      .collection("comments")
      .where("targetType", "==", "posts")
      .where("targetId", "==", postId)
      .orderBy(ORDER_BY.CREATED_AT, ORDER.ASC)
      .onSnapshot(
        snapshot => {
          const _mainComments = { ...mainCommentsRef.current }
          const _commentsMap = { ...commentsMapRef.current }
          let data

          snapshot.docChanges().forEach(change => {
            const doc = change.doc
            data = {
              id: doc.id,
              ...doc.data()
            }
            const { targetCommentId } = data

            if (change.type === "added") {
              if (targetCommentId) {
                _commentsMap[targetCommentId].push(data)
              } else {
                _mainComments[doc.id] = data
                _commentsMap[doc.id] = []
              }
            }
            if (change.type === "modified") {
              if (targetCommentId) {
                const idx = _commentsMap[targetCommentId]?.findIndex(
                  c => c.id === doc.id
                )
                if (idx !== undefined) {
                  _commentsMap[targetCommentId][idx] = data
                } else {
                  _commentsMap[targetCommentId].push(data)
                }
              } else {
                _mainComments[doc.id] = data
              }
            }
            if (change.type === "removed") {
              if (targetCommentId) {
                const idx = _commentsMap[targetCommentId].findIndex(
                  c => c.id === doc.id
                )
                _commentsMap[targetCommentId].splice(idx, 1)
              } else {
                delete _commentsMap[doc.id]
                delete _mainComments[doc.id]
              }
            }
          })

          mainCommentsRef.current = _mainComments
          setCommentsMap(_commentsMap)
        },
        e => {
          console.error(e)
          window.alert("댓글을 불러오는 데 실패했습니다.")
        }
      )
  }

  const onClickReaction = reaction => {
    if (!userId) {
      requestLogin()
      return
    }
    if (requestingRef.current) {
      return
    }
    requestingRef.current = true

    if (myReaction) {
      if (myReaction.data().type === reaction) {
        cancelReactionByRef(
          myReaction.ref,
          () => {
            requestingRef.current = false
            setMyReaction(null)
            setPost({
              ...post,
              [`${reaction}ReactionCount`]:
                post[[`${reaction}ReactionCount`]] - 1
            })
          },
          () => {
            requestingRef.current = false
          }
        )
      } else {
        const prevType = myReaction.data().type
        changeReactionType(
          myReaction.ref,
          reaction,
          () => {
            requestingRef.current = false
            setPost({
              ...post,
              [`${prevType}ReactionCount`]:
                post[[`${prevType}ReactionCount`]] - 1,
              [`${reaction}ReactionCount`]:
                post[[`${reaction}ReactionCount`]] + 1
            })
          },
          () => {
            requestingRef.current = false
          }
        )
      }
    } else {
      reactToPost(
        userId,
        postId,
        reaction,
        () => {
          requestingRef.current = false
          setPost({
            ...post,
            [`${reaction}ReactionCount`]: post[[`${reaction}ReactionCount`]] + 1
          })
        },
        () => {
          requestingRef.current = false
        }
      )
    }
  }

  const onClickEdit = () => {
    if (!isMine) {
      return window.alert("본인의 글이 아닙니다.")
    }
    history.push({
      pathname: `/community/${categoryId}/write`,
      state: { post: { id: postId, ...post } }
    })
  }

  const onClickDelete = () => {
    if (requestingRef.current) {
      return
    }
    if (!isMine) {
      return window.alert("본인의 글이 아닙니다.")
    }
    if (Object.keys(mainCommentsRef.current).length > 0) {
      if (
        window.confirm(
          "댓글이 달린 경우, 삭제를 요청하시면 관리자가 검토 후 처리합니다.\n삭제 요청 하시겠습니까?"
        )
      ) {
        return requestDelete()
      } else {
        return
      }
    }
    if (!window.confirm("정말 삭제하시겠습니까?")) {
      return
    }
    requestingRef.current = true
    db.collection("posts")
      .doc(postId)
      .update({ deletedat: fieldValue.serverTimestamp() })
      .then(() => {
        window.alert("삭제되었습니다.")
        history.replace(`/community/${categoryId}`)
      })
      .catch(e => {
        console.error(e)
        requestingRef.current = false
      })
  }

  const requestDelete = () => {
    requestingRef.current = true
    db.collection("posts")
      .doc(postId)
      .update({ deleterequestedat: fieldValue.serverTimestamp() })
      .then(() => {
        window.alert("삭제가 요청되었습니다.")
        history.replace(`/community/${categoryId}`)
      })
      .catch(e => {
        console.error(e)
        requestingRef.current = false
      })
  }

  const onClickReport = () => {
    if (!userId) {
      requestLogin()
      return
    }
    if (requestingRef.current) {
      return
    }
    if (
      !window.confirm(
        "신고하시겠습니까?\n신고된 글은 관리자가 검토 후 조치합니다."
      )
    ) {
      return
    }
    requestingRef.current = true
    db.collection("reports")
      .where("targetType", "==", "posts")
      .where("targetId", "==", postId)
      .where("userId", "==", userId)
      .get()
      .then(snapshot => {
        if (snapshot.size > 0) {
          window.alert("이미 신고하셨습니다.")
          requestingRef.current = false
        } else {
          db.collection("reports")
            .add({
              targetType: "posts",
              targetId: postId,
              userId,
              createdat: fieldValue.serverTimestamp()
            })
            .then(() => {
              window.alert("신고되었습니다. 감사합니다!")
              requestingRef.current = false
            })
            .catch(e => {
              requestingRef.current = false
              console.error(e)
            })
        }
      })
      .catch(e => {
        requestingRef.current = false
        console.error(e)
      })
  }

  const onUpdateComment = (id, _comment) => {
    mainCommentsRef.current[id] = _comment
  }

  const onUpdateSubComments = (mainCommentId, subComments) => {
    setCommentsMap({ ...commentsMap, [mainCommentId]: subComments })
  }

  const requestLogin = () => {
    dispatch(
      showLoginPopup({
        message: `환영합니다! 로그인 하여\n커뮤니티를 자유롭게 즐겨보세요.`
      })
    )
  }

  return (
    <Container>
      <ToListButton onClick={goToList} className="pc" />
      <Content>
        {post.deleterequestedat ? (
          <div className="more-menu">삭제 요청 중</div>
        ) : (
          <MoreMenus className="more-menu">
            {isMine ? (
              <div>
                <div className="more-menu__item edit" onClick={onClickEdit}>
                  <EditIcon /> 글 수정
                </div>
                <div className="more-menu__item delete" onClick={onClickDelete}>
                  <TrashIcon /> 삭제
                </div>
              </div>
            ) : (
              <div>
                <div className="more-menu__item report" onClick={onClickReport}>
                  <WarnIcon /> 글신고
                </div>
              </div>
            )}
          </MoreMenus>
        )}
        <p className="badge">
          {post.situation ||
            COMMUNITY_CATEGORIES.find(c => c.id === categoryId).name}
        </p>
        <p className="title">{post.title}</p>
        <ProfileAndTime
          userId={post.writerid}
          timestamp={post.createdat}
          isUpdated={post.updatedat}
          hideProfileOnMobile={true}
        />
        {post.reportCount < REPORT_HIDE_COUNT && (
          <div className="content">{post.content}</div>
        )}
        {post.reportCount < REPORT_HIDE_COUNT && post.images.length > 0 && (
          <div className="images">
            {post.images.map((src, i) => (
              <img alt="image" key={i} src={src} />
            ))}
          </div>
        )}
        {post.reportCount < REPORT_HIDE_COUNT && (
          <div className="reactions">
            {Object.values(COMMUNITY_REACTIONS).map((type, i) => (
              <div key={i}>
                <ReactionIcon
                  reaction={type}
                  count={post[`${type}ReactionCount`] || 0}
                  selected={myReaction?.data().type === type}
                  onClick={isMine ? null : () => onClickReaction(type)}
                />
              </div>
            ))}
          </div>
        )}
      </Content>
      <ToListButton onClick={goToList} className="mobile" />
      <CommentWrite
        categoryId={categoryId}
        postId={postId}
        situation={post.situation}
        requestLogin={requestLogin}
      />
      {!Object.keys(commentsMap).length && <CommentEmpty />}
      {Object.keys(commentsMap).map((commentId, i) => (
        <Comment
          key={i}
          comment={mainCommentsRef.current[commentId]}
          subComments={commentsMap[commentId].filter(c => !c.deletedat)}
          onUpdate={_comment => onUpdateComment(commentId, _comment)}
          onUpdateSubComments={_subComments =>
            onUpdateSubComments(commentId, _subComments)
          }
          requestLogin={requestLogin}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  min-height: 75vh;
  padding-top: 40px;
  padding-bottom: 48px;
  color: #121212;
  > * {
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 10px;
  }
  @media (max-width: 800px) {
    padding-top: 0;
    > * {
      border-radius: 0;
      border-left: none;
      border-right: none;
    }
  }
`

const Content = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 40px 40px 0;
  color: #121212;
  position: relative;
  .more-menu {
    position: absolute;
    top: 42px;
    right: 40px;
    font-size: 12px;
    &__item {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-right: 4px;
      }
    }
    .report {
      justify-content: center;
      width: 66px;
      height: 28px;
      color: #ef5e3b;
    }
    .edit,
    .delete {
      padding: 0 8px;
      width: 100%;
      height: 32px;
    }
    .delete {
      color: #ef5e3b;
    }
  }
  .badge {
    display: inline-block;
    height: 28px;
    line-height: 26px;
    padding: 0 12px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #555555;
    background: #f7f8fa;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 16px;
    max-height: 62px;
    overflow: hidden;
  }
  .content {
    margin-top: 24px;
    font-size: 16px;
    line-height: 20px;
    white-space: pre-wrap;
  }
  .images {
    padding: 0 24px;
    text-align: center;
    img {
      margin-top: 16px;
      max-width: 100%;
    }
  }
  .reactions {
    height: 99.83px;
    margin-top: 40px;
    margin-bottom: 48.33px;
    display: flex;
    justify-content: center;
    > div {
      margin-right: 38px;
      :last-child {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 800px) {
    margin-top: 0;
    padding: 32px 24px 0;
    border: none;
    .more-menu {
      top: 32px;
      right: 24px;
    }
    .badge {
      margin-bottom: 16px;
    }
    .title {
      margin-bottom: 12px;
    }
    .content {
      margin-top: 32px;
      font-size: 14px;
    }
    .reactions {
      height: 94.42px;
      margin-bottom: 62px;
      > div {
        margin-right: 12px;
      }
    }
  }
`

export default Detail
