import React, { useEffect } from 'react'
import styled from 'styled-components'

const CheckBottom = (props) => {

  const checkBottom = () => {
    
    const scrollHeight = document.documentElement.scrollHeight
    const clientHeight = document.documentElement.clientHeight
    const scrollTop = document.documentElement.scrollTop;
    const footerHeight = 250
    
    if (scrollTop + clientHeight >= scrollHeight - footerHeight && props.showLoading === false) {
      if (props.onBottom) {
        props.onBottom()
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", checkBottom);
    return () => {
      window.removeEventListener("scroll", checkBottom);
    }
  })

  return (
    <Container className={props.showLoading ? "" : "hidden"} />
  )
}

const Container = styled.div`
  text-align: center;
`

export default CheckBottom