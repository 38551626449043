import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import naverImg from "../images/naver.png"

function NaverLoginButton() {
  const location = useLocation()

  useEffect(() => {
    const initializeNaverLogin = () => {
      const naverLogin = new window.naver.LoginWithNaverId({
        clientId: "cL2yhpIHZyIQyueb0oDk",
        callbackUrl: "https://whattosay.kr/login?with=naver",
        isPopup: false,
        loginButton: { color: "green", type: 2, height: 48 }
      })
      naverLogin.init()
    }
    initializeNaverLogin()
  }, [])

  const onClick = () => {
    const naverLoginButton = document.getElementById("naverIdLogin_loginButton")
    if (naverLoginButton) {
      localStorage.setItem("redirect", location.pathname)
      naverLoginButton.click()
    }
  }

  return (
    <Button onClick={onClick}>
      <div id="naverIdLogin" style={{ display: "none" }} />
      <img src={naverImg} alt="naver logo" />
      <span>네이버 로그인</span>
    </Button>
  )
}

const Button = styled.div`
  width: 280px;
  padding-right: 13px;
  height: 3rem;
  margin: 0 auto;
  background: #03c75a;
  border: 1px solid #03c75a;
  border-radius: 23px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12.5px;
  img {
    height: 100%;
  }
`

export default NaverLoginButton
