import React from 'react'
import { useHistory } from 'react-router-dom'
import AuthManager from './AuthManager'

function AuthComponent({ children }) {
  const history = useHistory()

  return (
    <div>
      <AuthManager onLogout={()=>{ history.replace('/') }}/>
      {children}
    </div>
  )
}

export default AuthComponent
