import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import ProfileAndTime from "./ProfileAndTime"
import MoreMenus from "../../common/MoreMenus"
import { ReactComponent as WarnIcon } from "../../images/warn.svg"
import { ReactComponent as TrashIcon } from "../../images/delete.svg"
import { ReactComponent as LikeIcon } from "../../images/heart.svg"
import { ReactComponent as SubCommentArrowIcon } from "../../images/sub-comment-arrow.svg"
import * as reactionUtils from "../../common/reactionUtil"
import { MAX_COMMENT_LENGTH, REPORT_HIDE_COUNT } from "../../common/constants"
import { db, fieldValue } from "../../common/firebase.utils"
import { hasRestrictedWords } from "../../common/utils"
import { useUserInfo } from "../../hooks/useUserInfo"

function Comment({
  comment,
  subComments = [],
  onUpdate,
  onUpdateSubComments,
  requestLogin
}) {
  const [mainComment, setMainComment] = useState(comment)
  const { userId } = useUserInfo()
  const myId = userId
  const isMine = comment.writerId === myId
  const isDeleted = comment.deletedat != null
  const hideByReport = comment.reportCount >= REPORT_HIDE_COUNT
  const requestingRef = useRef(false)
  const subCommentCount = subComments.length

  if (isDeleted) {
    if (!subCommentCount) {
      return null
    }

    return (
      <CommentContainer>
        <p className="deleted">작성자가 삭제한 댓글입니다.</p>
        {subComments.map((c, i) => (
          <SubComment
            comment={c}
            key={i}
            onUpdateLikeCount={count => onUpdateSubCommentLikeCount(i, count)}
          />
        ))}
      </CommentContainer>
    )
  }

  if (hideByReport) {
    return (
      <CommentContainer>
        <ProfileAndTime
          userId={mainComment.writerId}
          timestamp={mainComment.createdat}
        />
        <p className="deleted">
          {REPORT_HIDE_COUNT}회 이상 신고되어 숨겨진 댓글입니다.
        </p>
        {subComments.map((c, i) => (
          <SubComment
            comment={c}
            key={i}
            onUpdateLikeCount={count => onUpdateSubCommentLikeCount(i, count)}
          />
        ))}
      </CommentContainer>
    )
  }

  const onClickDelete = () => {
    if (requestingRef.current) {
      return
    }
    if (!isMine) {
      return window.alert("본인의 댓글이 아닙니다.")
    }
    if (!window.confirm("정말 삭제하시겠습니까?")) {
      return
    }

    requestingRef.current = true
    db.collection("comments")
      .doc(comment.id)
      .update({ deletedat: fieldValue.serverTimestamp() })
      .then(() => {
        window.alert("삭제되었습니다.")
        requestingRef.current = false
      })
      .catch(e => {
        console.error(e)
        requestingRef.current = false
      })
  }

  const onClickReport = commentId => {
    if (!myId) {
      return requestLogin()
    }
    if (requestingRef.current) {
      return
    }
    if (
      !window.confirm(
        "신고하시겠습니까?\n신고된 댓글은 관리자가 검토 후 조치합니다."
      )
    ) {
      return
    }
    requestingRef.current = true
    db.collection("reports")
      .where("targetType", "==", "comments")
      .where("targetId", "==", commentId)
      .where("userId", "==", myId)
      .get()
      .then(snapshot => {
        if (snapshot.size > 0) {
          window.alert("이미 신고하셨습니다.")
          requestingRef.current = false
        } else {
          db.collection("reports")
            .add({
              targetType: "comments",
              targetId: commentId,
              userId: myId,
              createdat: fieldValue.serverTimestamp()
            })
            .then(() => {
              window.alert("신고되었습니다. 감사합니다!")
              requestingRef.current = false
            })
            .catch(e => {
              requestingRef.current = false
              console.error(e)
            })
        }
      })
      .catch(e => {
        requestingRef.current = false
        console.error(e)
      })
  }

  const onUpdateLikeCount = newCount => {
    const _comment = { ...mainComment, likeReactionCount: newCount }
    setMainComment(_comment)
    onUpdate(_comment)
  }

  const onUpdateSubCommentLikeCount = (index, newCount) => {
    const _subComments = [...subComments]
    _subComments[index] = { ...subComments[index], likeReactionCount: newCount }
    onUpdateSubComments(_subComments)
  }

  return (
    <CommentContainer>
      <ProfileAndTime
        userId={mainComment.writerId}
        timestamp={mainComment.createdat}
        pictureSize="48px"
      />
      <MoreMenus className="more-menu">
        {isMine ? (
          <div>
            <div className="more-menu__item delete" onClick={onClickDelete}>
              <TrashIcon /> 삭제
            </div>
          </div>
        ) : (
          <div>
            <div
              className="more-menu__item report"
              onClick={() => onClickReport(comment.id)}
            >
              <WarnIcon /> 댓글신고
            </div>
          </div>
        )}
      </MoreMenus>
      <div className="content reported">
        {hideByReport
          ? `${REPORT_HIDE_COUNT}회 이상 신고되어 숨겨진 댓글입니다.`
          : mainComment.content}
      </div>
      <Counts
        userId={myId}
        commentId={mainComment.id}
        likeCount={mainComment.likeReactionCount}
        subCommentCount={subCommentCount}
        onUpdateLikeCount={onUpdateLikeCount}
        requestLogin={requestLogin}
      />
      <SubCommentWrite
        postId={mainComment.targetId}
        mainCommentId={mainComment.id}
        requestLogin={requestLogin}
      />
      {subComments.map((c, i) => (
        <SubComment
          comment={c}
          key={i}
          onUpdateLikeCount={count => onUpdateSubCommentLikeCount(i, count)}
          onClickReport={onClickReport}
          requestLogin={requestLogin}
        />
      ))}
    </CommentContainer>
  )
}

function SubComment({
  comment,
  onUpdateLikeCount,
  onClickReport,
  requestLogin
}) {
  const { userId } = useUserInfo()
  const myId = userId
  const isMine = comment.writerId === myId
  const hideByReport = comment.reportCount >= REPORT_HIDE_COUNT
  const requestingRef = useRef(false)

  const [myLike, setMyLike] = useState()
  const [blockClick, setBlockClick] = useState(false)
  const likeCount = comment.likeReactionCount

  useEffect(() => {
    if (comment && myId) {
      reactionUtils
        .getMyReaction(myId, "comments", comment.id)
        .then(reaction => {
          setMyLike(reaction.ref)
        })
        .catch(() => setMyLike())
    }
  }, [comment])

  if (hideByReport) {
    return (
      <SubCommentContainer>
        <SubCommentArrowIcon />
        <ProfileAndTime
          userId={comment.writerId}
          timestamp={comment.createdat}
          pictureSize="24px"
        />
        <div className="content reported">{`${REPORT_HIDE_COUNT}회 이상 신고되어 숨겨진 댓글입니다.`}</div>
      </SubCommentContainer>
    )
  }

  const onClickDelete = () => {
    if (requestingRef.current) {
      return
    }
    if (!isMine) {
      return window.alert("본인의 댓글이 아닙니다.")
    }
    if (!window.confirm("정말 삭제하시겠습니까?")) {
      return
    }

    requestingRef.current = true
    db.collection("comments")
      .doc(comment.id)
      .update({ deletedat: fieldValue.serverTimestamp() })
      .then(() => {
        window.alert("삭제되었습니다.")
        requestingRef.current = false
      })
      .catch(e => {
        console.error(e)
        requestingRef.current = false
      })
  }

  const onClickLike = () => {
    if (blockClick) {
      return
    }
    if (!myId) {
      return requestLogin()
    }

    setBlockClick(true)
    if (myLike) {
      reactionUtils.cancelReactionByRef(
        myLike,
        () => {
          setMyLike()
          setBlockClick(false)
          onUpdateLikeCount(likeCount - 1)
        },
        () => setBlockClick(false)
      )
    } else {
      reactionUtils.likeComment(
        myId,
        comment.id,
        ref => {
          setMyLike(ref)
          setBlockClick(false)
          onUpdateLikeCount(likeCount + 1)
        },
        () => setBlockClick(false)
      )
    }
  }

  return (
    <SubCommentContainer liked={myLike != null}>
      <SubCommentArrowIcon />
      <ProfileAndTime
        userId={comment.writerId}
        timestamp={comment.createdat}
        pictureSize="24px"
      />
      <MoreMenus className="more-menu" dotsSize={16}>
        {isMine ? (
          <div>
            <div className="more-menu__item delete" onClick={onClickDelete}>
              <TrashIcon /> 삭제
            </div>
          </div>
        ) : (
          <div>
            <div
              className="more-menu__item report"
              onClick={() => onClickReport(comment.id)}
            >
              <WarnIcon /> 댓글신고
            </div>
          </div>
        )}
      </MoreMenus>
      <div className="content">{comment.content}</div>
      <div className="like" onClick={onClickLike}>
        <LikeIcon
          stroke={myLike ? "#5551FF" : "#C4C4C4"}
          fill={myLike ? "#5551FF" : "transparent"}
        />
        {likeCount}
      </div>
    </SubCommentContainer>
  )
}

function SubCommentWrite({ postId, mainCommentId, requestLogin }) {
  const [content, setContent] = useState("")
  const [isRegistering, setIsRegistering] = useState(false)
  const { userId } = useUserInfo()

  const onClickRegister = async () => {
    if (isRegistering) {
      return
    }
    const writerId = userId
    if (!writerId) {
      return requestLogin()
    }
    if (!registerable()) {
      return alert("댓글을 입력해주세요.")
    }
    if (content.length > MAX_COMMENT_LENGTH) {
      alert(
        `댓글은 최대 ${MAX_COMMENT_LENGTH}자까지 등록 가능합니다.\n(현재 ${content.length}자)`
      )
      return
    }
    setIsRegistering(true)

    const includedBadWord = await hasRestrictedWords(content)
    if (includedBadWord) {
      alert(`부적절한 단어 '${includedBadWord}'을(를) 포함하고 있습니다.`)
      setIsRegistering(false)
      return
    }

    const _comment = {
      targetType: "posts",
      targetId: postId,
      targetCommentId: mainCommentId,
      content,
      writerId,
      likeReactionCount: 0,
      reportCount: 0,
      createdat: fieldValue.serverTimestamp(),
      updatedat: null,
      deletedat: null
    }
    db.collection("comments")
      .add(_comment)
      .then(() => {
        setContent("")
        setIsRegistering(false)
      })
      .catch(() => setIsRegistering(false))
  }

  const registerable = () => content.trim().length > 0

  return (
    <SubCommentWriteContainer>
      <input
        type="text"
        placeholder="댓글을 입력하세요"
        value={content}
        onChange={({ target }) => setContent(target.value)}
        maxLength={MAX_COMMENT_LENGTH}
      />
      <div
        className={`register-button active--${registerable()}`}
        onClick={onClickRegister}
      >
        {isRegistering ? "등록 중" : "등록"}
      </div>
    </SubCommentWriteContainer>
  )
}

function Counts({
  userId,
  commentId,
  likeCount,
  subCommentCount,
  onUpdateLikeCount,
  requestLogin
}) {
  const [myLike, setMyLike] = useState()
  const [blockClick, setBlockClick] = useState(false)
  const noLike = likeCount === 0

  useEffect(() => {
    if (userId && commentId) {
      reactionUtils
        .getMyReaction(userId, "comments", commentId)
        .then(reaction => {
          setMyLike(reaction.ref)
        })
        .catch(() => setMyLike())
    }
  }, [userId, commentId])

  const onClickLike = () => {
    if (blockClick) {
      return
    }
    if (!userId) {
      return requestLogin()
    }
    setBlockClick(true)
    if (myLike) {
      reactionUtils.cancelReactionByRef(
        myLike,
        () => {
          setMyLike()
          setBlockClick(false)
          onUpdateLikeCount(likeCount - 1)
        },
        () => setBlockClick(false)
      )
    } else {
      reactionUtils.likeComment(
        userId,
        commentId,
        ref => {
          setMyLike(ref)
          setBlockClick(false)
          onUpdateLikeCount(likeCount + 1)
        },
        () => setBlockClick(false)
      )
    }
  }

  return (
    <CountsContainer liked={myLike != null} noLike={noLike}>
      <div className="like" onClick={onClickLike}>
        <LikeIcon
          stroke={myLike ? "#5551FF" : noLike ? "#C4C4C4" : "#666666"}
          fill={myLike ? "#5551FF" : "transparent"}
        />
        {likeCount}
      </div>
      <div className="comment">댓글 {subCommentCount}</div>
    </CountsContainer>
  )
}

const CommentContainer = styled.div`
  position: relative;
  margin-top: 16px;
  padding: 16px 24px 16px 32px;
  background: #fdfdfd;
  color: #121212;
  .deleted {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 0;
    color: #c4c4c4;
  }
  .more-menu {
    position: absolute;
    top: 28px;
    right: 24px;
    &__item {
      display: flex;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      svg {
        margin-right: 4px;
      }
    }
    .report {
      justify-content: center;
      width: 66px;
      height: 28px;
      color: #ef5e3b;
    }
    .edit,
    .delete {
      padding: 0 8px;
      width: 100%;
      height: 32px;
    }
    .delete {
      color: #ef5e3b;
    }
  }
  .content {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
  }
  @media (max-width: 800px) {
    margin-top: 8px;
    padding: 16px 24px;
  }
`

const CountsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  color: #666666;
  .like {
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 30px;
    border: 1px solid ${({ liked }) => (liked ? "#5551FF" : "#eaeaea")};
    border-radius: 23px;
    font-size: 12px;
    color: ${({ noLike }) => (noLike ? "#C4C4C4" : "#666666")};
    cursor: pointer;
    svg {
      margin-right: 5.17px;
    }
  }
  .comment {
    font-size: 13px;
  }
`

const SubCommentWriteContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  input {
    outline: none;
    -webkit-appearance: none;
    flex: 1;
    height: 40px;
    padding: 0 16px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    ::placeholder {
      color: #c4c4c4;
    }
  }
  .register-button {
    width: 92px;
    height: 40px;
    margin-left: 16px;
    line-height: 41px;
    text-align: center;
    background: #ebebeb;
    border-radius: 5.05699px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    &.active--true {
      color: #ffffff;
      background: #5551ff;
    }
  }
  @media (max-width: 800px) {
    .register-button {
      width: 57px;
      margin-left: 8px;
    }
  }
`

const SubCommentContainer = styled.div`
  margin: 16px 24px 8px 38px;
  position: relative;
  > svg {
    position: absolute;
    left: -16px;
    top: 9px;
  }
  .reported {
    color: #c4c4c4;
  }
  .more-menu {
    top: 0;
    right: 0;
  }
  .like {
    margin-top: 8px;
    width: 26px;
    height: 14px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    color: ${({ liked }) => (liked ? "#333333" : "#C4C4C4")};
    cursor: pointer;
    svg {
      margin-right: 5.17px;
    }
  }
  @media (max-width: 800px) {
    margin-left: 22px;
    margin-right: 8px;
  }
`

export default Comment
