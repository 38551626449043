import moment from "moment"
import { useSelector } from "react-redux"

export function useUserInfo() {
  const user = useSelector(state => state.user)
  const {
    authProvider,
    userId,
    authType,
    photo,
    nickname,
    unlimitedUntil,
    isLoading,
    isMembership,
    membershipFrom
  } = user

  const loggedIn = userId != null

  return {
    isLoading,
    loggedIn,
    authProvider,
    userId,
    authType,
    photo,
    nickname,
    unlimitedUntil,
    membership: isMembership ? "member" : "default",
    membershipFrom: membershipFrom
      ? moment(membershipFrom).format("YYYY년 MM월 DD일")
      : null
  }
}
