import { useDispatch } from "react-redux"
import { showToast } from "../store/slices/layerSlice"
import { useCallback } from "react"
import { useMemo } from "react"
import { ActionType } from "../layer/Toast"

export default function useToast() {
  const dispatch = useDispatch()

  const showWithArrow = useCallback(
    config => {
      dispatch(showToast({ ...config, actionType: ActionType.ARROW }))
    },
    [dispatch]
  )

  const showWithClose = useCallback(
    config => {
      dispatch(showToast({ ...config, actionType: ActionType.CLOSE }))
    },
    [dispatch]
  )

  const toast = useMemo(
    () => ({ showWithArrow, showWithClose }),
    [showWithArrow, showWithClose]
  )
  return toast
}
