import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
  loginPopup: {
    show: false,
    redirect: undefined,
    message: undefined
  },
  unlockPopup: {
    show: false
  },
  helpfulPopup: {
    show: false,
    count: 0,
    fromLastLogin: true
  },
  toast: {
    show: false,
    config: undefined
  }
}

export const layerSlice = createSlice({
  name: "layer",
  initialState: INITIAL_STATE,
  reducers: {
    showLoginPopup: (state, action) => {
      state.loginPopup.show = true
      state.loginPopup.redirect = action.payload?.redirect
      state.loginPopup.message = action.payload?.message
    },
    closeLoginPopup: state => {
      state.loginPopup.show = false
      state.loginPopup.redirect = undefined
      state.loginPopup.message = undefined
    },
    showUnlockPopup: state => {
      state.unlockPopup.show = true
    },
    closeUnlockPopup: state => {
      state.unlockPopup.show = false
    },
    showHelpfulPopup: (state, action) => {
      state.helpfulPopup.show = true
      state.helpfulPopup.count = action.payload?.count
      state.helpfulPopup.fromLastLogin = action.payload?.fromLastLogin
    },
    closeHelpfulPopup: state => {
      state.helpfulPopup.show = false
    },
    showToast: (state, action) => {
      state.toast.show = true
      state.toast.config = action.payload
    },
    closeToast: state => {
      state.toast.show = false
      state.toast.config = undefined
    }
  }
})

export const {
  showLoginPopup,
  closeLoginPopup,
  showUnlockPopup,
  closeUnlockPopup,
  showHelpfulPopup,
  closeHelpfulPopup,
  showToast,
  closeToast
} = layerSlice.actions

export default layerSlice.reducer
