import React, { useEffect } from 'react'
import { useSelector } from "react-redux"
import { usePrevState } from "../hooks/usePrevState"

function AuthManager({ onLogin, onLogout }) {
  const userId = useSelector(state => {
    return state.user.userId
  })
  const prevUserId = usePrevState(userId)

  useEffect(() => {
    if (!prevUserId && userId) {
      if (onLogin) {
        onLogin()
      }
    } else if (prevUserId && !userId) {
      if (onLogout) {
        onLogout()
      }
    }
  }, [userId])

  return <></>
}

export default AuthManager