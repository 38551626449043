import React, { useEffect } from 'react';
import { useDispatch } from "react-redux"
import { signIn, signOut } from "./store/slices/userSlice"
import { BrowserRouter, Route } from "react-router-dom"
import AuthSubscriber from "./common/AuthSubscriber"
import AuthRoute from "./common/AuthRoute"
import Layer from "./layer"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import theme from "./styles/theme.js"
import Main from "./main/Main"
import Templates from "./templates/Templates"
import NewWrite from "./write/NewWrite"
import My from "./my/My"
import Community from "./community"
// import Membership from "./membership"
import About from "./about"
import AnalyticsPageView from "./common/AnalyticsPageView"
import { db, fieldValue, logUserEvent } from "./common/firebase.utils"
import { useUserInfo } from "./hooks/useUserInfo"
import Login from "./login"
import "./styles/index.css"
import { showHelpfulPopup } from "./store/slices/layerSlice"

function App() {
  const { loggedIn, authType } = useUserInfo()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!loggedIn) {
      tryUserRestore()
    }

    let visitTimeout = undefined
    if (authType !== "A") {
      visitTimeout = setTimeout(() => {
        db.collection("statistics")
          .doc("actions")
          .update({
            visit: fieldValue.increment(1)
          })
      }, 1000)
    }
    return () => {
      if (visitTimeout) {
        clearTimeout(visitTimeout)
      }
    }
  }, [])

  const tryUserRestore = () => {
    const nid = localStorage.getItem("nid")
    if (!nid) {
      return
    }
    db.collection("users")
      .where("authProvider", "==", "naver")
      .where("uid", "==", nid)
      .get()
      .then(snap => {
        if (snap.empty) {
          dispatch(signOut())
        } else {
          const user = snap.docs[0].data()
          dispatch(
            signIn({
              userId: snap.docs[0].id,
              uid: user.uid,
              email: user.email,
              nickname: user.nickname,
              authType: user.authType,
              authProvider: user.authProvider,
              photo: user.photo,
              unlimitedUntil: user.unlimitedUntil,
              isMembership: user.isMembership,
              membershipFrom: user.membershipFrom
            })
          )

          const lastLoginAt = user.lastloginat
          db.collection("users").doc(user.id).update({
            lastloginat: fieldValue.serverTimestamp()
          })

          let copyQuery = db
            .collection("copies")
            .where("writerid", "==", user.id)
          if (lastLoginAt) {
            copyQuery = copyQuery.where("createdat", ">=", lastLoginAt)
          }
          copyQuery.get().then(snap => {
            const copyCount = snap.size
            if (copyCount > 0) {
              dispatch(
                showHelpfulPopup({
                  count: copyCount,
                  fromLastLogin: !!lastLoginAt
                })
              )
              logUserEvent("show_helpful_popup")
            }
          })
        }
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthSubscriber />
      <BrowserRouter>
        <GlobalStyle />
        <AnalyticsPageView />
        <Route exact path="/" component={Main} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/templates" component={Templates} />
        {/* <Route exact path="/membership" component={Membership} /> */}
        <Route exact path="/about" component={About} />
        <Route
          exact
          path={[
            "/community",
            "/community/:categoryId",
            "/community/:categoryId/:postId"
          ]}
          component={Community}
        />
        <AuthRoute exact path="/write" component={NewWrite} />
        <AuthRoute exact path="/my" component={My} />
        <Layer />
      </BrowserRouter>
    </ThemeProvider>
  )
}

const GlobalStyle = createGlobalStyle`
  @font-face { 
    font-family: "GmarketSansBold";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
      format("woff");
    font-weight: 700;
    font-style: bold;
  }

  * {
    box-sizing: border-box;
    --gnb-height: 5rem;
    @media (max-width: 800px) {
      --gnb-height: 59px;
      --status-height: 0px;
      --top-padding: calc(var(--gnb-height) + var(--status-height));
    }
  }  
  header,html,p,span {
    margin: 0;
    padding: 0;
  }
  body {
    padding: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    background: ${props => props.theme.background};
    font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  #root {
    height: 100%;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  h1, h2 {
    margin: 0;
    padding: 0;
  }
  button {
    background: none;
    cursor: pointer;
  }

  .hidden {
    display: none !important;
  }
  .clickable {
    cursor: pointer;
  }
  .no-scrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .center {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
`

export default App;
