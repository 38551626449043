import React, { useEffect } from "react"
import styled from "styled-components"
import { useLocation, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { closeLoginPopup, showHelpfulPopup } from "../store/slices/layerSlice"
import QueryString from "query-string-for-all"
import {
  db,
  functions,
  logUserEvent,
  fieldValue
} from "../common/firebase.utils"
import { signIn } from "../store/slices/userSlice"

function Login() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const provider = QueryString.parse(location.search)?.with

  useEffect(() => {
    if (provider === "naver") {
      loginWithNaver()
    }
  }, [])

  const handleUserInfo = (provider, uid, email, photo) => {
    db.collection("users")
      .where("authProvider", "==", provider)
      .where("uid", "==", uid)
      .get()
      .then(doc => {
        if (doc.size === 0) {
          var getNickname = functions.httpsCallable("getRandomNickname")
          getNickname().then(res => {
            const newUser = {
              authProvider: provider,
              uid,
              email,
              nickname: res.data.nickname,
              authType: "U",
              reported: 0,
              createdat: fieldValue.serverTimestamp(),
              lastloginat: fieldValue.serverTimestamp(),
              isMembership: false
            }
            if (photo) {
              newUser.photo = photo
            }
            db.collection("users")
              .add(newUser)
              .then(doc => {
                localStorage.setItem("aType", "U")

                dispatch(
                  signIn({
                    userId: doc.id,
                    uid,
                    email,
                    nickname: res.data.nickname,
                    authType: "U",
                    authProvider: provider,
                    photo,
                    isMembership: false
                  })
                )

                logUserEvent("sign_up", { method: provider })
                goBack()
              })
          })
        } else {
          const loginUser = doc.docs[0]
          let _user = loginUser.data()
          localStorage.setItem("aType", _user.authType)

          dispatch(
            signIn({
              userId: loginUser.id,
              uid: _user.uid,
              email: _user.email,
              nickname: _user.nickname,
              authType: _user.authType,
              authProvider: _user.authProvider,
              photo: _user.photo,
              unlimitedUntil: _user.unlimitedUntil,
              isMembership: _user.isMembership,
              membershipFrom: _user.membershipFrom
            })
          )

          const lastLoginAt = _user.lastloginat
          db.collection("users").doc(loginUser.id).update({
            lastloginat: fieldValue.serverTimestamp()
          })

          let copyQuery = db
            .collection("copies")
            .where("writerid", "==", loginUser.id)
          if (lastLoginAt) {
            copyQuery = copyQuery.where("createdat", ">=", lastLoginAt)
          }
          copyQuery.get().then(snap => {
            const copyCount = snap.size
            if (copyCount > 0) {
              dispatch(
                showHelpfulPopup({
                  count: copyCount,
                  fromLastLogin: !!lastLoginAt
                })
              )
              logUserEvent("show_helpful_popup")
            }
          })

          logUserEvent("login", { method: provider })
          goBack()
        }
      })
  }

  const loginWithNaver = () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: "cL2yhpIHZyIQyueb0oDk",
      callbackUrl: "https://whattosay.kr/login?with=naver"
    })
    naverLogin.init()
    naverLogin.getLoginStatus(status => {
      if (status) {
        dispatch(closeLoginPopup())
        const { id, email, profile_image } = naverLogin.user
        handleUserInfo("naver", id, email, profile_image)
      } else {
        goBack()
      }
    })
  }

  const goBack = () => {
    const redirect = localStorage.getItem("redirect") || "/"
    localStorage.removeItem("redirect")
    history.replace(redirect)
  }

  return (
    <Container>
      <p>로그인 중</p>
      <p>잠시만 기다려주세요.</p>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
      0deg,
      rgba(255, 168, 0, 0.16) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    #5551ff;
  > p:first-of-type {
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
  }
  > p:last-of-type {
    font-weight: 400;
    line-height: 14px;
    color: #9391ef;
    margin-bottom: 2rem;
  }
`

export default Login
