import { db, fieldValue } from "./firebase.utils"

export const getMyReaction = (userId, targetType, targetId) =>
  new Promise((resolve, reject) => {
    db.collection("reactions")
      .where("userId", "==", userId)
      .where("targetType", "==", targetType)
      .where("targetId", "==", targetId)
      .get()
      .then(snapshot => {
        if (snapshot.size > 0) {
          resolve(snapshot.docs[0])
        } else {
          resolve(null)
        }
      })
      .catch(e => {
        console.error(e)
        reject(e)
      })
  })

export const reactToPost = (userId, postId, type, onSuccess, onError) => {
  const _reaction = {
    targetType: "posts",
    targetId: postId,
    userId,
    type,
    createdat: fieldValue.serverTimestamp()
  }
  db.collection("reactions")
    .add(_reaction)
    .then(ref => {
      if (onSuccess) {
        onSuccess(ref)
      }
    })
    .catch(e => {
      console.error(e)
      if (onError) {
        onError(e)
      }
    })
}

export const likeComment = (userId, commentId, onSuccess, onError) => {
  const _reaction = {
    targetType: "comments",
    targetId: commentId,
    userId,
    type: "like",
    createdat: fieldValue.serverTimestamp()
  }
  db.collection("reactions")
    .add(_reaction)
    .then(ref => {
      if (onSuccess) {
        onSuccess(ref)
      }
    })
    .catch(e => {
      console.error(e)
      if (onError) {
        onError(e)
      }
    })
}

export const changeReactionType = (reactionRef, type, onSuccess, onError) => {
  reactionRef
    .update({ type })
    .then(ref => {
      if (onSuccess) {
        onSuccess(ref)
      }
    })
    .catch(e => {
      console.error(e)
      if (onError) {
        onError(e)
      }
    })
}

export const cancelReactionByRef = (ref, onSuccess, onError) => {
  ref
    .delete()
    .then(() => {
      if (onSuccess) {
        onSuccess()
      }
    })
    .catch(e => {
      console.error(e)
      if (onError) {
        onError(e)
      }
    })
}

export const cancelReaction = (
  userId,
  targetType,
  targetId,
  onSuccess,
  onError
) => {
  db.collection("reactions")
    .where("userId", "==", userId)
    .where("targetType", "==", targetType)
    .where("targetId", "==", targetId)
    .get()
    .then(snapshot => {
      if (snapshot.size > 0) {
        snapshot.docs[0].ref
          .delete()
          .then(() => {
            if (onSuccess) {
              onSuccess()
            }
          })
          .catch(e => {
            console.error(e)
            if (onError) {
              onError(e)
            }
          })
      } else {
        if (onError) {
          onError()
        }
      }
    })
    .catch(e => {
      console.error(e)
      if (onError) {
        onError(e)
      }
    })
}
