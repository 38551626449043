const theme = {
  background: "#FFFFFF",

  accent: "#3ffa6b",
  template_content: "#262626",
  template_tag: "#C2C2C2",
  darkgray: "#717174",
  lightergray: "#C2C2C2",
  bg_gray: "#eeeeee",
  bg_card: "#FCFCFC",
  copy_button: "#262626",
  
  primary_color: "#353635",
  secondary_color: "#2c2c2c",
  accent_color: "#3ffa6b",
  black: "#121212",
  dark_color: "#262626",
  orange: "#ef5e3b",
  light_grey: "#eeeeee",
  white: "#fcfcfc",
  grey_c2c2c2: "#C2C2C2",
  dark_grey: "#727272",
  light_purple: "#5f2eea"
}

export default theme;