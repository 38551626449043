import searchImg from "../images/find.svg"
import dropdownArrowImg from "../images/dropdown-arrow.svg"

const searchIcon = {
  display: "flex",
  alignItems: "center",
  ":before": {
    content: '" "',
    display: "none",
    marginRight: 8,
    height: 16,
    width: 16,
    backgroundSize: "16px 16px",
    backgroundImage: `url(${searchImg})`
  }
}

export const situationStyles = {
  control: styles => ({
    ...styles,
    backgroundColor: "white",
    border: "1px solid #EBEBEB",
    boxSizing: "border-box",
    borderRadius: "10px",
    width: "100%",
    height: "3rem",
    paddingLeft: "8px",
    paddingRight: "16px",
    boxShadow: "none",
    ":hover": {
      ...styles[":hover"],
      border: "1px solid #EBEBEB"
    },
    ":after": {
      content: '" "',
      display: "inline-block",
      marginLeft: 8,
      height: 24,
      width: 24,
      backgroundSize: "24px 24px",
      backgroundImage: `url(${dropdownArrowImg})`
    },
    "@media (max-width: 800px)": {
      ...styles["@media (max-width: 800px)"],
      border: "1px solid #E4E4E4",
      borderRadius: 0,
      height: "48px"
    }
  }),
  menu: styles => ({
    ...styles,
    backgroundColor: "white",
    "@media (max-width: 800px)": {
      ...styles["@media (max-width: 800px)"]
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: isDisabled ? "#ccc" : isFocused ? "black" : "#53575d",
      cursor: isDisabled ? "not-allowed" : "pointer",
      height: "48px",
      boxShadow: "0 -1px 0 0 rgba(0, 0, 0, 0.1)",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "transparent",
      paddingLeft: "24px",
      paddingRight: "12px",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "black" : "#ccc")
      }
    }
  },
  input: styles => ({
    ...styles,
    ...searchIcon,
    padding: "0",
    "@media (max-width: 800px)": {
      ...styles["@media (max-width: 800px)"],
      width: "100%",
      lineHeight: "27px"
    }
  }),
  placeholder: styles => ({
    ...styles,
    ...searchIcon,
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "27px",
    textAlign: "left",
    color: "#C4C4C4",
    padding: "0"
  }),
  singleValue: styles => ({ ...styles, ...searchIcon }),
  indicatorSeparator: styles => ({ ...styles, display: "none" }),
  dropdownIndicator: styles => ({
    ...styles,
    display: "none"
  })
}
