import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { analytics, logUserEvent } from "./firebase.utils"

function AnalyticsPageView() {
  let location = useLocation()

  useEffect(() => {
    const page_path = location.pathname + location.search
    analytics.setCurrentScreen(page_path)
    logUserEvent("page_view", { page_path })
  }, [location])
  return null
}

export default AnalyticsPageView