import { MIN_TEMPLATE_LENGTH, MAX_TEMPLATE_LENGTH } from "../common/constants"

const SPECIAL_CHARACTERS_SITUATION_ID = "nTgvr5l1HwM7ieI6bdZV"

interface ValidateOptions {
  blockList?: string[]
  tooMuchList?: string[]
  situationId?: string
}

const validate = async (
  content: string,
  options: ValidateOptions = {}
): Promise<string | undefined> => {
  if (!content) {
    return "내용을 입력해주세요."
  }

  const cleanedContent = content.replace(/[`~!@#$%^&*()_|?;'",.<>\s]/g, "")

  const length = cleanedContent.length
  if (
    length < MIN_TEMPLATE_LENGTH &&
    options.situationId !== SPECIAL_CHARACTERS_SITUATION_ID
  ) {
    return `내용이 너무 짧습니다. 공백과 특수문자를 제외하고 ${MIN_TEMPLATE_LENGTH}자 이상 입력해주세요.`
  }
  if (length > MAX_TEMPLATE_LENGTH) {
    return "내용이 너무 깁니다."
  }

  if (options.tooMuchList && options.tooMuchList.length > 0) {
    for (const toomuch of options.tooMuchList) {
      if (cleanedContent.includes(toomuch)) {
        return "작성하신 내용과 유사한 템플릿이 이미 존재합니다."
      }
    }
  }

  if (options.blockList && options.blockList.length > 0) {
    for (const nope of options.blockList) {
      if (cleanedContent.includes(nope) && length <= nope.length + 3) {
        return "템플릿 품질 관리에 최선을 다하고 있습니다.\n페이지에 안내된 삭제 대상에 포함되지 않는지 검토해주세요."
      }
    }
  }

  // todo check duplicate

  return
}

export default validate
