import React from 'react'
import styled from 'styled-components'
import { ReactComponent as EmptyImg } from '../images/empty.svg'

function Empty({ 
  text,
  hideButton,
  buttonText,
  onClickButton,
  buttonClickable = true
}) {
  return (
    <Container>
      <EmptyImg />
      <p>
        {text}
      </p>
      {!hideButton && 
        <Button onClick={onClickButton} clickable={buttonClickable}>
          {buttonText}
        </Button>
      }
    </Container>
  )
}

const Container = styled.div`
  width: 274px;
  margin: 128px auto;
  text-align: center;
  svg {
    margin: 0 auto;
  }
  p {
    margin-top: 23px;
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    word-break: keep-all;
  }
  @media (max-width: 800px) {
    width: 87%;
    max-width: 375px;
  }
`
const Button = styled.div`
  width: 100%;
  height: 38px;
  margin-top: 35px;
  border: 1px solid #5551FF;
  border-radius: 30px;
  font-size: 14px;
  line-height: 38px;
  color: #5551FF;
  cursor: pointer;
  pointer-events: ${props => props.clickable ? 'inherit' : 'none'};
`

export default Empty
