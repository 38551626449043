import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import LoginPopup from "./LoginPopup"
import {
  closeLoginPopup,
  closeUnlockPopup,
  closeHelpfulPopup
} from "../store/slices/layerSlice"
import UnlockPopup from "./UnlockPopup"
import HelpfulPopup from "./HelpfulPopup"
import Toast from "./Toast"

function Layer() {
  const layer = useSelector(state => state.layer)
  const { loginPopup, unlockPopup, helpfulPopup, toast } = layer
  const dispatch = useDispatch()
  const history = useHistory()

  return (
    <div>
      {loginPopup.show && (
        <LoginPopup
          onLogin={() => {
            dispatch(closeLoginPopup())
            if (loginPopup.redirect) {
              history.push(loginPopup.redirect)
            }
          }}
          onCloseClick={() => dispatch(closeLoginPopup())}
          message={loginPopup.message}
        />
      )}
      {unlockPopup.show && (
        <UnlockPopup onCloseClick={() => dispatch(closeUnlockPopup())} />
      )}
      {helpfulPopup.show && (
        <HelpfulPopup
          copyCount={helpfulPopup.count}
          fromLastLogin={helpfulPopup.fromLastLogin}
          onCloseClick={() => dispatch(closeHelpfulPopup())}
        />
      )}
      {toast.show && <Toast config={toast.config} />}
    </div>
  )
}

export default Layer
