import React from "react"
import styled from "styled-components"

export default function MembershipBanner({ onClick }: { onClick: () => void }) {
  return (
    <div className="w-auto relative h-[150px] sm:h-auto max-w-[600px] mx-auto sm:aspect-[5]">
      <Container
        className="cursor-pointer overflow-hidden h-full border-b border-[#ebebeb] sm:border sm:rounded-lg sm:mt-4 sm:flex-row flex-col text-white flex items-center gap-3 sm:gap-0 sm:justify-between pt-[22px] sm:py-[30px] pr-8 pl-6"
        onClick={onClick}
      >
        <p className="text-center sm:text-left text-sm leading-[22px] font-medium">
          템플릿 등록하기 귀찮다면,
          <br />
          <b className="text-base leading-none font-bold">5,900원</b> 한 번의
          결제로 평생 무제한 이용하세요
        </p>
        <div
          id="join-button"
          className="bg-[rgba(85,81,255,0.10)] rounded-[4px] text-sm font-semibold leading-[48px] px-6 cursor-pointer"
        >
          멤버십 가입하기
        </div>
      </Container>
    </div>
  )
}

const Container = styled.div`
  background-image: linear-gradient(
    290.11deg,
    #7869d6 -15.93%,
    #3d3764 14.76%,
    #16122c 45.45%
  );

  #join-button {
    --border-angle: 0turn; // For animation.
    --main-bg: conic-gradient(
      from var(--border-angle),
      #2a2880,
      #2a2880 5%,
      #2a2880 60%,
      #2a2880 95%
    );

    border: solid 1px #5551ff;
    border-radius: 4px;

    background-position: center center;

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }

    &:hover {
      border: 1px solid transparent;
      --gradient-border: conic-gradient(
        from var(--border-angle),
        #5551ff 25%,
        #88ff00,
        #5551ff 99%,
        #5551ff
      );
      background: 
    // padding-box clip this background in to the overall element except the border.
        var(--main-bg) padding-box,
        // border-box extends this background to the border space
        var(--gradient-border) border-box,
        // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
        var(--main-bg) border-box;

      animation: bg-spin 2.5s linear infinite;
    }

    @property --border-angle {
      syntax: "<angle>";
      inherits: true;
      initial-value: 0turn;
    }
  }
`
