import React from "react"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import Card from "../home/Card"
import skeletonImg from "../images/skeleton.png"
import { useUserInfo } from "../hooks/useUserInfo"
import { isLimited } from "./utils"

function MasonryView({
  isLoading,
  templates,
  blurFrom,
  onSelectTag,
  defaultColumns = 3,
  gap = "24px",
  isSearchResult = false
}) {
  const { loggedIn, userId, authType, membership, unlimitedUntil } =
    useUserInfo()

  if (isLoading) {
    return (
      <MasonryContainer defaultColumns={defaultColumns} gap={gap}>
        {Array(6)
          .fill()
          .map((_, i) => (
            <img
              key={i}
              className="animate-pulse"
              id="skeleton"
              src={skeletonImg}
              alt="skeleton"
            />
          ))}
      </MasonryContainer>
    )
  }

  const isAdmin = authType === "A"
  const isMine = writerId => loggedIn && userId === writerId
  const isLimitedUser = membership === "default" && isLimited(unlimitedUntil)

  return (
    <>
      <MasonryContainer defaultColumns={defaultColumns} gap={gap}>
        {templates?.map((t, i) => {
          return (
            <Card
              key={i}
              data={t}
              onSelectTag={onSelectTag}
              isAdmin={isAdmin}
              isMine={isMine(t.writerid)}
              isLimited={isLimitedUser}
              userId={userId}
              blur={blurFrom !== undefined ? i >= blurFrom : false}
              isSearchResult={isSearchResult}
            />
          )
        })}
      </MasonryContainer>
    </>
  )
}

function MasonryContainer({ children, defaultColumns, gap }) {
  const breakpointColumns = {
    default: defaultColumns,
    800: 1
  }
  return (
    <Container gap={gap}>
      <Masonry
        breakpointCols={breakpointColumns}
        className="masonry-container"
        columnClassName="masonry-column"
      >
        {children}
      </Masonry>
    </Container>
  )
}

const Container = styled.div`
  max-width: 1440px;
  min-height: 85%;
  margin: 0 auto;
  padding: 0 24px;
  .masonry-container {
    display: flex;
    width: auto;
    margin-left: ${({ gap }) => `-${gap}`}; /* gutter offset */
  }
  .masonry-column {
    width: 364px;
    padding-left: ${({ gap }) => gap}; /* gutter size */
    background-clip: padding-box;
    #skeleton {
      width: 100%;
      height: 284px;
      margin-bottom: 24px;
      @media (max-width: 800px) {
        margin-bottom: 0.5rem;
      }
    }
  }
  @media (max-width: 800px) {
    padding: 0;
  }
`

export default MasonryView
