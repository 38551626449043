import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthComponent from './AuthComponent'
import { useUserInfo } from "../hooks/useUserInfo"

function AuthRoute({ component: Component, ...rest }) {
  const { isLoading, loggedIn } = useUserInfo()

  return (
    <Route
      {...rest}
      render={props =>
        isLoading || loggedIn ? (
          <AuthComponent>
            <Component {...props} />
          </AuthComponent>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  )
}

export default AuthRoute
