import React, { useEffect } from "react"
import { auth, db, functions, logUserEvent, fieldValue } from "./firebase.utils"
import { useDispatch } from "react-redux"
import { signIn, signOut } from "../store/slices/userSlice"
import { showHelpfulPopup } from "../store/slices/layerSlice"

function AuthSubscriber() {
  const dispatch = useDispatch()

  useEffect(() => {
    let unscribeAuth = auth.onAuthStateChanged(user => {
      if (user) {
        db.collection("users")
          .where("uid", "==", user.uid)
          .get()
          .then(doc => {
            if (doc.size === 0) {
              var getNickname = functions.httpsCallable("getRandomNickname")
              getNickname().then(res => {
                db.collection("users")
                  .add({
                    authProvider: "google",
                    uid: user.uid,
                    email: user.email,
                    nickname: res.data.nickname,
                    photo: user.photoURL,
                    authType: "U",
                    reported: 0,
                    createdat: fieldValue.serverTimestamp(),
                    lastloginat: fieldValue.serverTimestamp()
                  })
                  .then(doc => {
                    localStorage.setItem("aType", "U")

                    dispatch(
                      signIn({
                        userId: doc.id,
                        uid: user.uid,
                        email: user.email,
                        nickname: res.data.nickname,
                        authType: "U",
                        authProvider: "google",
                        photo: user.photoURL,
                        isMembership: false
                      })
                    )

                    logUserEvent("sign_up", { method: "Google" })
                  })
              })
            } else {
              const loginUser = doc.docs[0]
              let _user = loginUser.data()
              localStorage.setItem("aType", _user.authType)
              if (!_user.photo) {
                loginUser.ref.update({ photo: user.photoURL })
              }
              if (!_user.email) {
                loginUser.ref.update({ email: user.email })
              }

              dispatch(
                signIn({
                  userId: loginUser.id,
                  uid: _user.uid,
                  email: user.email,
                  nickname: _user.nickname,
                  authType: _user.authType,
                  authProvider: _user.authProvider,
                  photo: _user.photo || user.photoURL,
                  unlimitedUntil: _user.unlimitedUntil,
                  isMembership: _user.isMembership,
                  membershipFrom: _user.membershipFrom
                })
              )

              const lastLoginAt = _user.lastloginat
              db.collection("users").doc(loginUser.id).update({
                lastloginat: fieldValue.serverTimestamp()
              })

              let copyQuery = db
                .collection("copies")
                .where("writerid", "==", loginUser.id)
              if (lastLoginAt) {
                copyQuery = copyQuery.where("createdat", ">=", lastLoginAt)
              }
              copyQuery.get().then(snap => {
                const copyCount = snap.size
                if (copyCount > 0) {
                  dispatch(
                    showHelpfulPopup({
                      count: copyCount,
                      fromLastLogin: !!lastLoginAt
                    })
                  )
                  logUserEvent("show_helpful_popup")
                }
              })
              logUserEvent("login", { method: "Google" })
            }
          })
      } else {
        if (!localStorage.getItem("nid")) {
          dispatch(signOut())
        }
      }
    })

    return () => unscribeAuth()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <></>
}

export default AuthSubscriber
