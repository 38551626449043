import React from "react"
import styled from "styled-components"
import CommunityItem from "./CommunityItem"
import skeletonImg from "../images/post-skeleton.svg"
import skeletonImgM from "../images/post-skeleton-m.svg"

function CommunityListView({
  isLoading,
  posts,
  onClickPost,
  isNotice,
  hideWriter
}) {
  if (isLoading) {
    return (
      <Container>
        {Array(2)
          .fill()
          .map((_, i) => (
            <img
              key={i}
              id="skeleton"
              className="animate-pulse"
              alt="skeleton"
              src={window.innerWidth <= 800 ? skeletonImgM : skeletonImg}
            />
          ))}
      </Container>
    )
  }

  if (posts?.length === 0) {
    return null
  }

  return (
    <Container>
      {posts?.map(p => (
        <CommunityItem
          post={p}
          key={p.id}
          onClickPost={onClickPost}
          isNotice={isNotice}
          hideWriter={hideWriter}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  #skeleton {
    margin-bottom: 16px;
    width: 100%;
  }
  @media (max-width: 800px) {
    #skeleton {
      margin-bottom: 8px;
    }
  }
`

export default CommunityListView
