import React from "react"
import styled from "styled-components"
import { ReactComponent as WriteImg } from "../images/write.svg"

function FloatingWriteButton({ onClickWrite }) {
  return (
    <Button onClick={onClickWrite}>
      <WriteImg />
    </Button>
  )
}

const Button = styled.div`
  position: fixed;
  right: 16.16px;
  bottom: 24px;
  z-index: 1;
  width: 57px;
  height: 57px;
  background: #333333;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.16));
  @media (min-width: 801px) {
    display: none;
  }
`

export default FloatingWriteButton
