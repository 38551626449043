import React, { useState } from 'react'
import styled from 'styled-components'
import { db, fieldValue } from "../common/firebase.utils"

function Subscribe() {
  const [emailInput, setEmailInput] = useState("")
  const [pending, setPending] = useState(false)

  const isEmailValid = email => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  const onClickSubscribe = () => {
    setPending(true)
    db.collection("subscribers")
      .doc(emailInput)
      .set({
        email: emailInput,
        subscribedat: fieldValue.serverTimestamp(),
        canceledat: null
      })
      .then(() => {
        setEmailInput("")
        setPending(false)
        alert("감사합니다!\n알림 신청이 완료되었습니다.")
      })
      .catch(e => {
        console.error("Error subscribing", e)
        setPending(false)
        alert(
          "죄송합니다. 오류가 발생했습니다.\n오류가 지속될 시 help@whattosay.kr로 문의 부탁드립니다."
        )
      })
  }

  return (
    <Container>
      <Content>
        <p>Subscribe</p>
        <p>
          Just pick, it's <br />
          ready to be used
        </p>
        <p>
          뭐라고할까는 꾸준히 업데이트가 진행되고 있어요.
          <br />
          중요한 업데이트 소식을 받아보시겠어요?
        </p>
      </Content>
      <Form>
        <input
          value={emailInput}
          type="email"
          placeholder="평소 사용하는 이메일 주소를 입력해주세요"
          onChange={({ target }) => setEmailInput(target.value)}
        />
        <button
          disabled={pending || !isEmailValid(emailInput)}
          onClick={onClickSubscribe}
        >
          {pending ? "신청 중" : "알림 신청"}
        </button>
        <p>
          알림 신청 시 이메일 주소 수집 및 이메일 수신에 동의하는 것으로
          간주됩니다.
        </p>
      </Form>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  padding: 102px 0;
  white-space: nowrap;
  align-items: center;
  justify-content: space-evenly;
  background: #5d5fef;
  color: white;
  @media (max-width: 800px) {
    flex-direction: column;
    height: auto;
    padding: 0 25px;
    justify-content: start;
  }
`

const Form = styled.div`
  width: 34.5rem;
  margin-top: 1.3rem;
  input {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 3.5rem;
    background: #ffffff;
    border-radius: 4px;
    outline: none;
    padding: 0 1rem;
    color: black;
    ::placeholder {
      color: #acacb1;
    }
    @media (max-width: 800px) {
      display: block;
      width: 100%;
      height: 40px;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
  button {
    width: 100%;
    height: 3.5rem;
    margin-top: 1.2rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1.125rem;
    background: #0f0e2c;
    color: white;
    border: none;
    cursor: default;
    :enabled {
      cursor: pointer;
    }
    @media (max-width: 800px) {
      width: 100%;
      height: 40px;
      margin-top: 0;
    }
  }
  p {
    margin-top: 1rem;
    color: #e7e7e7;
    font-size: 14px;
    @media (max-width: 800px) {
      margin-bottom: 56px;
      margin-left: 0;
      margin-top: 8px;
      white-space: pre-wrap;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    margin-top: 40px;
  }
`

const Content = styled.div`
  width: 26.5rem;
  color: white;
  font-size: 2.875rem;
  font-weight: 500;
  > :first-child {
    font-family: "Space Grotesk", sans-serif;
    color: #acacac;
    font-size: 1.125rem;
  }
  > :nth-child(2) {
    margin-top: 1rem;
    font-family: "Space Grotesk", sans-serif;
    line-height: 100%;
  }
  > :nth-child(3) {
    margin-top: 2.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 140%; /* 1.4rem */
    word-break: keep-all;
    white-space: pre-wrap;
  }
  @media (max-width: 800px) {
    text-align: center;
    margin-top: 56px;
    width: 100%;
    > :first-child {
      font-size: 14px;
    }
    > :nth-child(2) {
      margin-top: 8px;
      font-size: 18px;
      br {
        display: none;
      }
    }
    > :nth-child(3) {
      margin-top: 16px;
    }
  }
`

export default Subscribe
