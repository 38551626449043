import React, { useCallback, useEffect, useRef, useState } from "react"
import AsyncSelect from "react-select/async"
import { useUserInfo } from "../hooks/useUserInfo"
import { situationStyles } from "../styles/select-style"
import { db, fieldValue, logUserEvent } from "./firebase.utils"
import pinIconSrc from "../images/icon-pin.svg"
import pinOffIconSrc from "../images/icon-pin-gray.svg"
import useToast from "../hooks/useToast"
import { useDispatch } from "react-redux"
import { showLoginPopup } from "../store/slices/layerSlice"

const MAX_PIN_COUNT = 3

function SituationSelect({
  className,
  selected,
  onSelect,
  placeholder = "상황별 템플릿 보기",
  useShowAll = true
}) {
  const [situationOptions, setSituationOptions] = useState([])

  const { userId, loggedIn } = useUserInfo()
  const toast = useToast()
  const pinCount = useRef()
  const dispatch = useDispatch()

  useEffect(() => {
    const loadSituations = pinned => {
      db.collection("situations")
        .orderBy("title")
        .get()
        .then(snapshot => {
          const _situationOptions = []
          snapshot.docs.forEach(doc => {
            let data = doc.data()
            if (data.title?.length > 0) {
              _situationOptions.push({
                id: doc.id,
                value: data.title,
                label: data.title,
                key: data.key,
                related: data.relatedKeywords,
                isPinned: pinned?.includes(doc.id)
              })
            }
          })
          setSituationOptions(_situationOptions)
        })
    }

    if (useShowAll && userId) {
      db.collection("favorites")
        .where("userId", "==", userId)
        .where("targetType", "==", "situation")
        .get()
        .then(snapshot => {
          const pinned = []
          snapshot.docs.forEach(doc => pinned.push(doc.data().targetId))
          pinCount.current = pinned.length
          loadSituations(pinned)
        })
    } else {
      loadSituations()
    }
  }, [userId, useShowAll])

  const loadSituationOptions = (input, callback) => {
    const _input = input.trim()
    if (!_input.length) {
      return callback([])
    }
    const cleanedInput = _input.replace(/[`~!@#$%^&*()_|?;'",.<>\s]/g, "")
    let suggestions = situationOptions.filter(option =>
      option.key.includes(cleanedInput)
    )
    const related = situationOptions.filter(
      s =>
        !suggestions.includes(s) &&
        s.related &&
        s.related.find(r => r.includes(cleanedInput))
    )
    suggestions = [...suggestions, ...related]
    callback(suggestions)
  }

  const Option = props => {
    const [isPending, setIsPending] = useState(false)
    const {
      innerRef,
      innerProps,
      children,
      getStyles,
      options,
      data: { isPinned, id, value }
    } = props
    const onClickPin = e => {
      e.stopPropagation()

      if (!loggedIn) {
        dispatch(showLoginPopup())
        return
      }

      if (isPinned) {
        setIsPending(true)
        db.collection("favorites")
          .where("userId", "==", userId)
          .where("targetType", "==", "situation")
          .where("targetId", "==", id)
          .get()
          .then(snapshot => {
            if (!snapshot.empty) {
              snapshot.docs[0].ref.delete().then(() => setIsPending(false))
              if (useShowAll) {
                options.shift()
              }
              options.find(o => o.id === id).isPinned = false
              setSituationOptions(options)
              pinCount.current -= 1
              toast.showWithClose({
                iconSrc: pinIconSrc,
                text: `${value} 즐겨찾기가 해제되었습니다.`
              })
            } else {
              setIsPending(false)
            }
          })
      } else {
        if (pinCount.current === MAX_PIN_COUNT) {
          toast.showWithClose({
            iconSrc: pinIconSrc,
            text: `즐겨찾기는 최대 ${MAX_PIN_COUNT}개까지 등록 가능합니다.`
          })
          return
        }

        logUserEvent("click_situation_pin", { situation: value })

        setIsPending(true)
        db.collection("favorites")
          .add({
            userId,
            targetType: "situation",
            targetId: id,
            createdat: fieldValue.serverTimestamp()
          })
          .then(() => {
            if (useShowAll) {
              options.shift()
            }
            options.find(o => o.id === id).isPinned = true
            setSituationOptions(options)
            pinCount.current += 1
            setIsPending(false)
            toast.showWithClose({
              iconSrc: pinIconSrc,
              text: `${value} 즐겨찾기가 등록되었습니다.`
            })
          })
      }
    }

    return (
      <div ref={innerRef} style={getStyles("option", props)} {...innerProps}>
        {children}
        {value &&
          useShowAll &&
          (isPending ? (
            <div>...</div>
          ) : (
            <div onClick={onClickPin}>
              <img src={isPinned ? pinIconSrc : pinOffIconSrc} alt="pin" />
            </div>
          ))}
      </div>
    )
  }

  const getSortedOptions = useCallback(() => {
    if (userId && useShowAll) {
      const pinned = []
      let others = []
      for (let i = 0; i < situationOptions.length; i++) {
        const option = situationOptions[i]
        if (option.isPinned) {
          pinned.push(option)
          if (
            pinned.length === MAX_PIN_COUNT &&
            i !== situationOptions.length - 1
          ) {
            others = others.concat(situationOptions.slice(i + 1))
            break
          }
        } else {
          others.push(option)
        }
      }
      return pinned.concat(others)
    }
    return situationOptions
  }, [userId, situationOptions, useShowAll])

  return (
    <AsyncSelect
      isLoading={situationOptions?.length === 0}
      className={className}
      placeholder={placeholder}
      defaultOptions={
        useShowAll
          ? [{ value: null, label: "전체보기" }].concat(getSortedOptions())
          : getSortedOptions()
      }
      loadOptions={loadSituationOptions}
      styles={situationStyles}
      value={situationOptions.find(option => option.value === selected) || null}
      onChange={option => {
        if (option) {
          onSelect(option.value)
        }
      }}
      components={{ Option }}
    />
  )
}

export default SituationSelect
