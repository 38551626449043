import { useEffect, useState } from "react"

export default function useIsMobile() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800)

  useEffect(() => {
    const resizeLisnener = () => {
      setIsMobile(window.innerWidth <= 800)
    }
    window.addEventListener("resize", resizeLisnener)
    return () => {
      window.removeEventListener("resize", resizeLisnener)
    }
  }, [])

  return isMobile
}
