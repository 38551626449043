import React, { useState, useEffect, useCallback } from "react"
import styled, { css } from "styled-components"
import { db, logUserEvent } from "../common/firebase.utils"
import searchImg from "../images/find.svg"
import SituationSelect from "../common/SituationSelect"
import { SearchBox } from "react-instantsearch-hooks-web"
import { useUserInfo } from "../hooks/useUserInfo"
import { useDispatch } from "react-redux"
import { showLoginPopup } from "../store/slices/layerSlice"

const defaultSituations = [
  "업무 보고",
  "업무요청",
  "끝인사",
  "계절 인사",
  "요청 회신"
]

const searchTimeout = 450
let timerId = undefined

function Hero({ currentSituation, onSelectSituation, setShowSearchResults }) {
  const [popularSituations, setPopularSituations] = useState([])
  const { loggedIn } = useUserInfo()
  const dispatch = useDispatch()

  useEffect(() => {
    db.collection("ui")
      .doc("templates")
      .get()
      .then(doc => {
        const { popular_situations } = doc.data()
        setPopularSituations(popular_situations)
      })
      .catch(e => {
        setPopularSituations(defaultSituations)
      })
  }, [])

  const onClickPopularSituation = situation => {
    onSelectSituation(situation)
    logUserEvent("click_popular_situation", { situation })
  }

  const queryHook = useCallback(
    (query, search) => {
      if (timerId) {
        clearTimeout(timerId)
      }

      timerId = setTimeout(() => {
        if (query.length > 1) {
          search(query)
          setShowSearchResults(true)
          logUserEvent("view_search_results", { search_term: query })
        } else {
          setShowSearchResults(false)
        }
      }, searchTimeout)
    },
    [setShowSearchResults]
  )

  const onClickSearchBox = e => {
    if (!loggedIn) {
      e.preventDefault()
      e.stopPropagation()
      dispatch(showLoginPopup())
    }
  }

  return (
    <Container>
      <SearchContainer>
        <div>
          <SituationSelect
            className="situation-select"
            selected={currentSituation}
            onSelect={onSelectSituation}
          />
        </div>
        <SearchBox
          queryHook={loggedIn ? queryHook : () => {}}
          placeholder="템플릿 본문 검색하기 (BETA)"
          className="searchbox"
          onClick={onClickSearchBox}
        />
      </SearchContainer>
      {popularSituations.length > 0 && (
        <PopularSituations>
          <p>최근 방문한 분들이 이런 템플릿을 자주 찾으셨어요!</p>
          <div className="no-scrollbar">
            {popularSituations.map((situation, i) => (
              <Situation
                key={i}
                onClick={() => onClickPopularSituation(situation)}
                isSelected={situation === currentSituation}
              >
                {situation}
              </Situation>
            ))}
          </div>
        </PopularSituations>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 272px;
  padding-top: 120px;
  text-align: center;
  color: white;
  background: #5c58ff;
  @media (max-width: 800px) {
    height: auto;
    padding-top: 59px;
    background: transparent;
  }
`
const SearchContainer = styled.div`
  margin: 0 auto;
  padding: 0 24px;
  max-width: 1044px;
  display: flex;
  position: relative;
  > div:first-child {
    width: 324px;
    margin-right: 16px;
  }
  .searchbox {
    flex: 1;
    form {
      height: 3rem;
      font-size: 1rem;
      overflow: hidden;
      border: 1px solid #ebebeb;
      border-radius: 10px;

      ::before {
        margin-right: 8;
        height: 1rem;
        width: 1rem;
        left: 20px;
        background-size: 16px 16px;
        background-image: url(${searchImg});
      }
      input {
        box-shadow: none;
        color: black;
        border: none;
        padding-left: 3rem;
        overflow: hidden;
        ::placeholder {
          color: #c4c4c4;
        }
      }
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0;
    > div:first-child {
      width: 100%;
    }
    .searchbox {
      form {
        border-radius: 0;
        border-top: none;
      }
    }
  }
`
const PopularSituations = styled.div`
  margin: 1rem auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  > p {
    font-size: 1rem;
  }
  @media (max-width: 800px) {
    display: none;
  }
`
const Situation = styled.span`
  display: inline-block;
  margin: 0 8px;
  line-height: 28px;
  padding: 0 15px;
  border-radius: 74.5px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    background: #7471ff;
    color: white;
  }
  ${({ isSelected }) =>
    isSelected
      ? css`
          background: #504bff;
          color: white;
          border: 1px solid #504bff;
        `
      : css`
          border: 1px solid #e3e2ff;
          color: #c1c0e8;
        `}
`

export default Hero
