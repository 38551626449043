import React from "react"
import styled from "styled-components"
import { TABS } from "./My"

function Tabs({ onClick, currentTab }) {
  return (
    <Container onClick={onClick}>
      <div
        id={TABS.COPIED}
        className={"selected--" + (currentTab === TABS.COPIED)}
      >
        복사한 템플릿
      </div>
      <div id={TABS.MY} className={"selected--" + (currentTab === TABS.MY)}>
        나의 템플릿
      </div>
      <div
        id={TABS.COMMUNITY}
        className={"selected--" + (currentTab === TABS.COMMUNITY)}
      >
        나의 커뮤니티 글
      </div>
    </Container>
  )
}

const Container = styled.div`
  font-size: 14px;
  margin-top: 16px;
  > div {
    height: 60px;
    line-height: 60px;
    padding: 0 32px;
    cursor: pointer;
    border-bottom: 1px solid #ebebeb;
    &.selected--true {
      color: #5551ff;
    }
  }
  @media (min-width: 801px) {
    > div:last-child {
      border: none;
    }
  }
  @media (max-width: 800px) {
    display: flex;
    margin-top: 0;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 48px;
      line-height: unset;
      padding: 0;
      width: 50%;
      &.selected--true {
        color: inherit;
        border-bottom: 2px solid #333333;
        font-weight: 500;
      }
    }
  }
`

export default Tabs
