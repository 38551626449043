import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import ToListButton from "./ToListButton"
import { db } from "../common/firebase.utils"
import { addViewCount, getPastTimeText } from "../common/utils"

function NoticeDetail({ noticeId, goToList }) {
  const [notice, setNotice] = useState({})

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  useEffect(() => {
    db.collection("notices")
      .doc(noticeId)
      .get()
      .then(doc => {
        setNotice(doc.data())
        addViewCount("notices", noticeId)
      })
      .catch(e => {
        window.alert("공지사항을 불러오는 데 실패했습니다.")
      })
  }, [noticeId])

  return (
    <Container noFrame={notice.noFrame}>
      <ToListButton className="pc" onClick={goToList} />
      <Content noFrame={notice.noFrame}>
        <p className="type">{notice.type}</p>
        <p className="title">{notice.title}</p>
        <div className="writer">
          <span className="nick">관리자</span>
          {notice.createdat && (
            <span className="date">| {getPastTimeText(notice.createdat)}</span>
          )}
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: notice.content || "" }}
        />
      </Content>
      <ToListButton className="mobile" onClick={goToList} />
    </Container>
  )
}

const Container = styled.div`
  min-height: 75vh;
  padding-top: 40px;
  padding-bottom: 48px;
  color: #121212;
  ${({ noFrame }) =>
    noFrame
      ? ""
      : css`
          > * {
            border: 1px solid #ebebeb;
            box-sizing: border-box;
            border-radius: 10px;
          }
        `};
  .mobile {
    display: none;
  }
  @media (max-width: 800px) {
    padding-top: 0;
    padding-bottom: 0;
    min-height: unset;
    border-bottom: 1px solid #ebebeb;
    .pc {
      display: none;
    }
    .mobile {
      display: inline-flex;
    }
    > * {
      border-radius: 0;
      border: none;
    }
  }
`

const Content = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 24px 40px 40px;
  color: #121212;
  position: relative;
  .type {
    margin: 16px 0 10px;
    display: inline-block;
    height: 28px;
    line-height: 26px;
    padding: 0 12px;
    background: #f7f8fa;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    font-size: 12px;
    color: #555555;
  }
  .title {
    font-weight: 400;
    font-size: 24px;
  }
  .writer {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-size: 13px;
    .nick {
      font-weight: 500;
    }
    .date {
      margin-left: 4px;
      font-weight: 350;
      color: #bfbfbf;
    }
  }
  .content {
    margin-top: 24px;
    font-size: 16px;
    line-height: 21px;
    color: #121212;
    white-space: pre-wrap;
    h1 {
      font-size: 32px;
      line-height: unset;
    }
    h2 {
      font-size: 18px;
      line-height: unset;
    }
    img {
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    margin: 0;
    padding: 32px 24px 24px;
    .type {
      margin: 0;
      margin-bottom: 16px;
    }
    .title {
      font-weight: 500;
    }
    .writer {
      margin-top: 12px;
    }
    .content {
      margin-top: 32px;
      font-size: 14px;
      line-height: 20px;
      h1 {
        font-size: 30px;
        line-height: unset;
      }
      h2 {
        font-size: 16px;
        line-height: unset;
      }
    }
  }
`

export default NoticeDetail
