import React from 'react'
import styled from 'styled-components'
import { Link } from "react-router-dom"
import { ReactComponent as BottomArrowIcon } from "../images/hero-hand-arrow.svg"
import { ReactComponent as SinceImg } from "../images/since.svg"

function Header() {
  return (
    <Container className="px-[24px] border-b border-[#e1e1e1]">
      <div>
        <p>마음만 준비하세요, 표현은</p>
        <div className="flex items-center">
          <p>
            뭐라고할까에서
            <SinceImg className="w-[34px] md:w-[45px] absolute left-[calc(50%-76px)] md:left-[calc(50%-204px)] top-[8px] md:top-[31px]" />
          </p>
          <Link to="/templates">
            <Arrow />
          </Link>
        </div>
      </div>
      <div>
        <p>지금 템플릿 보러 가기!</p>
        <BottomArrowIcon className="w-[153px] md:w-auto" />
      </div>
    </Container>
  )
}

function Arrow() {
  return (
    <svg
      className="md:h-[145px] w-full stroke-[2px] md:stroke-[0.861032px]"
      width="338"
      height="145"
      viewBox="0 0 338 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.430516"
        y="0.430516"
        width="337.139"
        height="144.139"
        rx="72.0695"
        stroke="black"
        strokeWidth="current"
      />
      <circle
        cx="72.5"
        cy="72.5"
        r="72.0695"
        stroke="black"
        strokeWidth="current"
      />
      <circle cx="72" cy="72" r="48" fill="#0F0E2C" />
      <path
        d="M265.995 37.0637C266.064 36.994 268.177 36.4606 270.69 35.8788C273.203 35.2971 275.287 34.9101 275.32 35.0181C275.353 35.1265 275.613 36.8833 275.898 38.9225C276.469 43.0173 277.852 47.7984 279.33 50.7885C283.936 60.1072 292.2 66.2776 302.224 67.8813L304.777 68.2898V76.5233L302.463 76.9142C287.306 79.4754 277.424 90.8901 275.576 107.972C275.455 109.088 275.311 110 275.255 110C274.744 110 266.274 107.944 266.114 107.781C265.998 107.663 266.084 106.618 266.306 105.458C268.757 92.6139 275.775 82.1625 284.882 77.7925L287.066 76.7442L158.777 76.61L158.777 68.2787L287.034 68.1441L284.275 66.7367C277.121 63.0857 271.456 55.9238 268.12 46.3108C267.169 43.5721 265.735 37.3285 265.995 37.0637Z"
        fill="black"
      />
      <path
        d="M72.4413 40.1523C72.4413 61.6781 83.2042 72.441 104.73 72.441M104.73 72.441C83.2042 72.441 72.4413 83.2039 72.4413 104.73M40.1526 72.441C61.6784 72.441 72.4413 61.6781 72.4413 40.1523M72.4413 104.73C72.4413 83.2039 61.6784 72.441 40.1526 72.441"
        stroke="white"
        strokeWidth="1.07629"
      />
    </svg>
  )
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden;
  background: #fbfbfa;
  > div {
    max-width: 902px;
    margin: 0 auto;
  }
  > div:first-of-type {
    margin-top: calc(104px + var(--gnb-height));
    font-family: "Giants";
    font-size: 4rem;
    line-height: 120%;
    > * {
      height: 145px;
    }
    > :first-child {
      padding: 31px 0;
      text-align: center;
      border-radius: 68.883px 68.883px 68.883px 0px;
      border: 1px solid #14112a;
    }
    > :last-child {
      p {
        position: relative;
        text-align: center;
        flex: 1;
        line-height: 145px;
        border-radius: 68.883px 68.883px 68.883px 0px;
        background: #5551ff;
        color: #ffffff;
      }
      a {
        max-width: 338px;
      }
    }
    @media (max-width: 800px) {
      margin-top: calc(64.5px + var(--gnb-height));
      font-size: 1.5rem;
      > * {
        height: 56px;
      }
      > :first-child {
        padding: 12px 0;
      }
      > :last-child {
        p {
          line-height: 56px;
          border-radius: 28px;
        }
        a {
          width: 132px;
        }
      }
    }
  }
  > div:last-of-type {
    height: 193px;
    position: relative;
    > p {
      font-family: "BM-HANNA";
      font-size: 24px;
      line-height: 140%;
      position: absolute;
      left: 156px;
      top: 51.08px;
    }
    > svg {
      position: absolute;
      top: 23px;
      left: 283px;
    }
    @media (max-width: 800px) {
      height: 127.5px;
      overflow: hidden;
      > p {
        font-size: 14px;
        top: 32px;
        left: unset;
        right: 180px;
      }
      > svg {
        top: -24px;
        left: unset;
        right: 60px;
        transform: rotate(-10deg);
      }
    }
  }
`

export default Header
