import React, { useEffect, useRef } from "react"
import { useInfiniteHits } from "react-instantsearch-hooks-web"
import Empty from "../common/Empty"
import MasonryView from "../common/MasonryView"
import { PUBLIC_LIMIT_COUNT_SITUATION } from "../common/constants"

function SearchResult({ isLimited, ...props }) {
  const { hits, isLastPage, showMore, results } = useInfiniteHits(props)
  const sentinelRef = useRef(null)

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            showMore()
          }
        })
      })
      if (!isLimited) {
        observer.observe(sentinelRef.current)
      }
      return () => {
        observer.disconnect()
      }
    }
  }, [isLastPage, isLimited, showMore])

  if (
    !results.query.length ||
    (!results.__isArtificial && results.nbHits === 0)
  ) {
    return <Empty text="검색 결과가 없습니다." hideButton />
  }

  return (
    <>
      <MasonryView
        isLoading={false}
        templates={hits.filter(hit => !hit.deletedat)}
        isSearchResult={true}
        blurFrom={isLimited ? PUBLIC_LIMIT_COUNT_SITUATION : undefined}
      />
      <div ref={sentinelRef} aria-hidden="true" />
    </>
  )
}

export default SearchResult
