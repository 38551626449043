import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isPageTop } from '../common/utils'
import { ReactComponent as ArrowImg } from '../images/go-to-top.svg'

const ScrollArrow = ({ aboveOtherButton }) => {
  const [showArrow, setShowArrow] = useState(!isPageTop())

  const checkScrollTop = () => {
    const isOnTop = isPageTop()
    if (!showArrow && !isOnTop) {
      setShowArrow(true)
    } else if (showArrow && isOnTop) {
      setShowArrow(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop)
    return () => {
      window.removeEventListener("scroll", checkScrollTop)
    }
  })

  return (
    <Arrow
      className={showArrow ? "" : "hidden"}
      onClick={scrollTop}
      aboveOtherButton={aboveOtherButton}
    >
      <ArrowImg />
    </Arrow>
  )
}

const Arrow = styled.div`
  position: fixed;
  right: 1rem;
  bottom: ${({ aboveOtherButton }) =>
    aboveOtherButton ? "calc(1.5rem + 8px + 64px)" : "1.5rem"};
  z-index: 1;
  cursor: pointer;
  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    right: 16.16px;
    bottom: ${({ aboveOtherButton }) =>
      aboveOtherButton ? "calc(24px + 8px + 57px)" : "24px"};
    svg {
      width: 57px;
      height: 57px;
    }
  }
`

export default ScrollArrow;