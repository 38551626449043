import React, { useState, useRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import { db, fieldValue, logUserEvent } from "../common/firebase.utils"
import NewWriteView from "./NewWriteView"
import { Template, Situation } from "../common/types"
import { useUserInfo } from "../hooks/useUserInfo"
import { update } from "../store/slices/userSlice"
import { UNLIMITED_DATE, UNLIMITED_DATE_FORMAT } from "../common/constants"
import moment from "moment"
import validate from "./contentValidator"
import { hasRestrictedWords } from "../common/utils"
import useToast from "../hooks/useToast"
import thankImgSrc from "../images/icon-thank.svg"

function NewWrite() {
  const [input, setInput] = useState("")
  const [content, setContent] = useState("")
  const [suggestions, setSuggestions] = useState<Situation[]>([])
  const [situation, setSituation] = useState<Situation | undefined>(undefined)
  const [tags, setTags] = useState<string[]>([])
  const [tagInput, setTagInput] = useState("")
  const [receiver, setReceiver] = useState("colleague")
  const [language, setLanguage] = useState("kor")
  const [isRegistering, setIsRegistering] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)
  const tagInputRef = useRef<HTMLInputElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const situations = useRef<Situation[]>([])
  const blockContents = useRef<string[]>([])
  const blockSituations = useRef<string[]>([])
  const tooMuchKeywords = useRef<string[]>([])
  const dispatch = useDispatch()
  const { userId, nickname } = useUserInfo()
  const toast = useToast()

  useEffect(() => {
    textAreaRef.current?.focus()
    loadSituations()
    loadBlocks()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateSuggestions()
  }, [input]) // eslint-disable-line react-hooks/exhaustive-deps

  const onClickClear = () => {
    setInput("")
    setSituation(undefined)
    inputRef.current?.focus()
  }

  const onClickRegister = async () => {
    if (isRegistering) {
      return
    }
    setIsRegistering(true)

    let _tags = tags
    if (tagInput.length > 0) {
      _tags = handleTagsChange([...tags, tagInput])
    }

    const writerid = userId
    const writernick = nickname
    if (!writerid || !writernick) {
      alert("로그인 정보가 없습니다. 재로그인 부탁드립니다.")
      return setIsRegistering(false)
    }

    if (!situation) {
      alert("상황을 선택해 주세요.")
      return setIsRegistering(false)
    }

    const trimmedContent = content.trim()
    const message = await validate(trimmedContent, {
      blockList: blockContents.current,
      tooMuchList: tooMuchKeywords.current,
      situationId: situation.id
    })
    if (message) {
      alert(message)
      return setIsRegistering(false)
    }

    const includedBadWord = await hasRestrictedWords(trimmedContent)
    if (includedBadWord) {
      if (
        !window.confirm(
          `부적절한 단어가 포함되었는지 확인해주세요.\n이대로 등록하시겠어요?`
        )
      ) {
        return setIsRegistering(false)
      }
    }

    let templateId = ""
    const template: Template = {
      writerid,
      writernick,
      createdat: fieldValue.serverTimestamp(),
      deletedat: null,
      situationid: situation.id,
      situation: situation.title,
      content: trimmedContent,
      pasted: 0,
      reported: false,
      language,
      length: trimmedContent.length,
      receiver
    }
    db.collection("templates")
      .add(template)
      .then(doc => {
        templateId = doc.id
        extendUserUnlimitedDate(templateId)

        _tags.map(t =>
          db.collection("tags").add({
            title: t,
            templateid: templateId
          })
        )

        setIsRegistered(true)
        toast.showWithArrow({
          iconSrc: thankImgSrc,
          text: "템플릿이 등록되었습니다. 감사합니다!",
          pushLocation: "/templates",
          actionText: "바로 확인하러 가기"
        })

        logUserEvent("add_template", {
          situation
        })
      })
      .catch(e => {
        alert("죄송합니다. 오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.")
        console.error("error adding template: ", e)
        setIsRegistering(false)
      })
  }

  const extendUserUnlimitedDate = (templateId: string) => {
    const extendedDate = moment()
      .add(UNLIMITED_DATE, "days")
      .format(UNLIMITED_DATE_FORMAT)
    const data = {
      unlimitedUntil: extendedDate,
      lastTemplateId: templateId
    }
    db.collection("users")
      .doc(userId)
      .update(data)
      .then(() => {
        dispatch(update(data))
      })
  }

  const loadSituations = () => {
    db.collection("situations")
      .orderBy("templateCount", "asc")
      .get()
      .then(doc => {
        const data: Situation[] = []
        doc.docs.forEach(doc => {
          const situationData = doc.data()
          if (situationData.title) {
            data.push({
              id: doc.id,
              key: situationData.key,
              title: situationData.title,
              templateCount: situationData.templateCount,
              relatedKeywords: situationData.relatedKeywords || []
            })
          }
        })
        situations.current = data
        updateSuggestions()
      })
  }

  const loadBlocks = () => {
    db.collection("blocks")
      .doc("write")
      .get()
      .then(doc => {
        const data = doc.data()
        if (data) {
          const { content, situations, toomuch } = data
          blockContents.current = content
          blockSituations.current = situations
          tooMuchKeywords.current = toomuch
        }
      })
  }

  const updateSuggestions = () => {
    let filtered = situations.current
    const _input = input.trim()
    if (!_input.length) {
      filtered = []
    } else if (_input.length > 0) {
      const cleanedInput = _input.replace(/[`~!@#$%^&*()_|?;'",.<>\s]/g, "")
      filtered = filtered.filter(situation => {
        return situation.key.includes(cleanedInput)
      })

      const exist = filtered.find(e => e.key === cleanedInput)

      const relatedSituations = situations.current.filter(
        s =>
          !filtered.includes(s) &&
          s.relatedKeywords &&
          s.relatedKeywords.find(rk => rk.includes(cleanedInput))
      )
      filtered = [...filtered, ...relatedSituations]

      if (_input.length > 2 && !exist) {
        const invalids = blockSituations.current || []
        if (
          !invalids.includes(_input) &&
          !filtered.find(f => f.key === `${cleanedInput}인사`)
        ) {
          filtered.push({
            id: "add",
            title: `'${_input}' 상황을 추가할래요`,
            key: "",
            templateCount: 0
          })
        }
      }
    }
    if (filtered !== suggestions) {
      setSuggestions(filtered)
    }
  }

  const onClickSuggestion = (suggestion: {
    id: string
    title: string
    key: string
    templateCount: number
  }) => {
    const { id, title } = suggestion
    if (id === "add") {
      onClickAddSituation()
    } else {
      setInput(title)
      setSituation(suggestion)
    }
  }

  const onClickAddSituation = () => {
    const _input = input.trim()
    const cleaned = _input.replace(/[`~!@#$%^&*()_|?;'",.<>\s]/g, "")
    db.collection("situations")
      .add({
        title: _input,
        key: cleaned,
        templateCount: 0,
        relatedKeywords: []
      })
      .then(ref =>
        setSituation({
          id: ref.id,
          title: _input,
          key: cleaned,
          templateCount: 0,
          relatedKeywords: []
        })
      )
  }

  const handleTagsChange = (tags: string[]) => {
    if (tags.length > 5) {
      return tags.slice(0, 5)
    }
    const cleanedTags = tags.map(t =>
      t.trim().replace(/[`~!@#$%^&*()_|?;'",.<>\s]/g, "")
    )
    const unique = Array.from(new Set<string>(cleanedTags))
    setTags(unique)
    return unique
  }

  return (
    <NewWriteView
      situation={situation}
      suggestions={suggestions}
      textAreaRef={textAreaRef}
      tagInputRef={tagInputRef}
      content={content}
      tags={tags}
      tagInput={tagInput}
      setTagInput={setTagInput}
      input={input}
      inputRef={inputRef}
      receiver={receiver}
      language={language}
      isRegistering={isRegistering}
      isRegistered={isRegistered}
      setContent={setContent}
      handleTagsChange={handleTagsChange}
      setInput={setInput}
      setReceiver={setReceiver}
      setLanguage={setLanguage}
      onClickClear={onClickClear}
      onClickSuggestion={onClickSuggestion}
      onClickRegister={onClickRegister}
    />
  )
}

export default NewWrite
