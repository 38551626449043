import React from "react"
import styled from "styled-components"
import checkOnImg from "../images/check-on.svg"
import checkOffImg from "../images/check-off.svg"

const Checkbox = ({ id, checked, onChange, label, className = "" }) => {
  return (
    <Container className={className}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={() => onChange(!checked)}
      />
      <label htmlFor={id}>{label}</label>
    </Container>
  )
}

const Container = styled.div`
  input {
    display: none;
  }
  input + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #5551ff;
    ::before {
      content: "";
      background-image: url(${checkOffImg});
      background-size: 16px 16px;
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  input:checked + label {
    ::before {
      background-image: url(${checkOnImg});
    }
  }
`

export default Checkbox
