import React, { useRef, useState } from "react"
import styled from "styled-components"
import Checkbox from "../common/Checkbox"
import { ReactComponent as CloseButton } from "../images/close.svg"
import { db, fieldValue, storage } from "../common/firebase.utils"
import { useUserInfo } from "../hooks/useUserInfo"
import { signOut } from "../store/slices/userSlice"
import { useDispatch } from "react-redux"

const WithdrawPopup = ({ onCloseClick }) => {
  const { userId } = useUserInfo()
  const [reasons, setReasons] = useState({
    "no-situation": false,
    "less-language": false,
    "bad-templates": false,
    inconvenient: false,
    needless: false
  })
  const [inProcess, setInProcess] = useState(false)
  const textareaRef = useRef()
  const dispatch = useDispatch()

  const onConfirm = async () => {
    setInProcess(true)
    const userDoc = await db.collection("users").doc(userId).get()
    const data = userDoc.data()
    const photo = data.photo
    delete data["photo"]
    delete data["email"]
    const reason = getReason()
    db.collection("withdrawals")
      .doc(userId)
      .set({
        ...data,
        withdrawReason: reason.length ? reason : null,
        deletedat: fieldValue.serverTimestamp()
      })
      .then(() => {
        userDoc.ref.delete()
        if (photo?.includes("firebasestorage")) {
          storage.ref().child(`profile/${userId}`).delete()
        }
        dispatch(signOut())
      })
  }

  const getReason = () => {
    const reason = []
    for (const _reason in reasons) {
      if (reasons[_reason]) {
        reason.push(_reason)
      }
    }
    if (textareaRef.current?.value.length) {
      reason.push(textareaRef.current?.value)
    }
    return reason
  }

  return (
    <Background>
      <Container disabled={inProcess}>
        <CloseButton id="close_button" onClick={onCloseClick} fill="#666666" />
        <Texts>
          <p>
            정말 뭐라고할까를 떠나시겠어요?{" "}
            <span role="img" aria-label="sad">
              😢
            </span>
          </p>
          <small>
            이유를 알려주시면 참고하여 개선하겠습니다. <br />
            (다중 선택 가능)
          </small>
        </Texts>
        <Checkboxes>
          <Checkbox
            id="no-situation"
            label="필요한 상황의 템플릿이 없어요."
            checked={reasons["no-situation"]}
            onChange={checked =>
              setReasons({ ...reasons, "no-situation": checked })
            }
          />
          <Checkbox
            id="less-language"
            label="필요한 언어의 템플릿이 적어요."
            checked={reasons["less-language"]}
            onChange={checked =>
              setReasons({ ...reasons, "less-language": checked })
            }
          />
          <Checkbox
            id="bad-templates"
            label="유용하지 않은 템플릿이 많아요."
            checked={reasons["bad-templates"]}
            onChange={checked =>
              setReasons({ ...reasons, "bad-templates": checked })
            }
          />
          <Checkbox
            id="inconvenient"
            label="사용하기가 불편해요."
            checked={reasons["inconvenient"]}
            onChange={checked =>
              setReasons({ ...reasons, inconvenient: checked })
            }
          />
          <Checkbox
            id="needless"
            label="사용할 일이 없어요."
            checked={reasons["needless"]}
            onChange={checked => setReasons({ ...reasons, needless: checked })}
          />
        </Checkboxes>
        <textarea
          className="no-scrollbar"
          placeholder="기타 의견이나 위 선택에 대한 부가 정보가 있다면 남겨주세요."
          maxLength={300}
          ref={textareaRef}
        />
        <Buttons>
          {inProcess ? (
            <div>처리 중입니다. 잠시만 기다려주세요.</div>
          ) : (
            <div onClick={onConfirm}>
              네, 계정을 삭제할게요.
              <br />
              <small>*개인정보와 이용 내역이 영구적으로 삭제됩니다.</small>
            </div>
          )}
          <div onClick={onCloseClick}>
            {!inProcess && "아뇨, 더 이용해 볼게요."}
          </div>
        </Buttons>
      </Container>
    </Background>
  )
}

const Checkboxes = styled.div`
  margin-top: 12px;
  padding: 0 64px;
  > * {
    margin-top: 12px;
  }
  input + label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
  }
  @media (max-width: 800px) {
    padding: 0 48px;
  }
`

const Texts = styled.div`
  color: #333333;
  text-align: center;
  > :first-child {
    font-family: "GmarketSansBold";
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
  }
  > :last-child {
    margin-top: 0.5rem;
    line-height: 24px;
    font-size: 14px;
  }
  small br {
    display: none;
  }
  @media (max-width: 800px) {
    margin-top: 20%;
    > :first-child {
      font-size: 18px;
      line-height: 18px;
    }
    small br {
      display: block;
    }
  }
`
const Buttons = styled.div`
  width: 327px;
  margin: 29.26px auto 0;
  > * {
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
  }
  > :first-child {
    color: #ffffff;
    border-radius: 48px;
    background: #333333;
    padding: 0.8rem 0;
    > small {
      line-height: 1rem;
      font-size: 12px;
      color: #efefef;
    }
  }
  > :last-child {
    margin-top: 21px;
    color: #333333;
  }
  @media (max-width: 800px) {
    position: fixed;
    bottom: 57px;
    width: calc(100% - 48px);
    margin-left: 24px;
  }
`

const Container = styled.div`
  pointer-events: ${({ inProcess }) => (inProcess ? "none" : "unset")};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33.75rem;
  padding: 56px 0 33px;
  border-radius: 10px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  background: linear-gradient(180deg, #e2e1fb 0%, #f9f9f9 66.02%);
  border: 1px solid #e4e4e4;
  text-align: center;
  overflow: hidden;
  cursor: default;

  .mobile {
    display: none;
  }

  textarea {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 12px 64px 0;
    height: 57px;
    padding: 8px 12px;
    outline: none;
    overflow: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    font-size: 14px;
    line-height: 20px;
    &::placeholder {
      color: #979797;
      font-size: 12px;
    }
  }

  #close_button {
    position: absolute;
    top: 28.5px;
    right: 28.5px;
    opacity: 0.8;
    cursor: pointer;
    :hover {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    justify-content: flex-start;

    .mobile {
      display: inherit;
    }
    textarea {
      margin-left: 48px;
      margin-right: 48px;
    }
  }
`

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`

export default WithdrawPopup
