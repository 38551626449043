import React from "react"
import styled from "styled-components"
import { ORDER_BY, ORDER } from "./constants"
import ascImg from "../images/ascending.svg"
import descImg from "../images/descending.svg"

const ORDER_NAME = {
  [ORDER_BY.COMMENT]: "댓글순",
  [ORDER_BY.COPIED]: "인기순",
  [ORDER_BY.CREATED_AT]: "시간순",
  [ORDER_BY.REACTION]: "반응순",
  [ORDER_BY.VIEW]: "조회순",
  [ORDER_BY.LAST_COPIED_AT]: "최근복사순",
  [ORDER_BY.LENGTH]: "길이순"
}

function OrderByRadio({
  orderBys = [],
  currentOrderBy,
  currentOrder,
  onChange,
  disable
}) {
  return (
    <Container selected={currentOrderBy} order={currentOrder} disable={disable}>
      {orderBys.map((orderBy, i) => (
        <OrderByItem
          key={i}
          onClick={() => onChange(orderBy)}
          disable={disable}
        >
          {ORDER_NAME[orderBy]}
          {!disable && currentOrderBy === orderBy && (
            <img
              alt="order"
              src={currentOrder === ORDER.ASC ? ascImg : descImg}
            />
          )}
        </OrderByItem>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: black;
  pointer-events: ${props => (props.disable ? "none" : "inherit")};

  @media (max-width: 800px) {
    top: 0;
    right: 16px;
  }
`

const OrderByItem = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: auto;
  padding: 0 8px;
  font-size: 1rem;
  line-height: 2em;
  text-align: center;
  color: ${props => (props.disable ? "#c5c5c5 !important" : "unset")};
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin-left: 6px;
  }
  ::after {
    display: inline-block;
    content: "";
    width: 1px;
    height: 1rem;
    margin-left: 8px;
    margin-right: -8px;
    background-color: #e4e4e4;
  }
  :last-child {
    &::after {
      display: none;
    }
  }
  @media (max-width: 800px) {
    height: 32px;
    font-size: 14px;
    line-height: 100%;
  }
`

export default OrderByRadio
