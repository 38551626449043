import React, { useEffect } from "react"
import Header from "./Header"
import Body from "./Body"
import PageLayout from "../common/PageLayout"

function Main() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  return (
    <PageLayout>
      <Header />
      <Body />
    </PageLayout>
  )
}

export default Main
