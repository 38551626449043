import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LogoImg } from '../images/logo_footer.svg'
import { ReactComponent as InstagramImg } from "../images/instagram.svg"
import { URL } from "../common/constants"
import { Link } from "react-router-dom"

function Footer() {
  return (
    <Container>
      <div>
        <About>
          <LogoImg />
          <Desc>
            뭐라고 할까 고민하는 모두를 위한 <br />
            handy pick 템플릿 아카이브
          </Desc>
          <SNS>
            <a href={URL.INSTAGRAM} target="_blank" rel="noopener noreferrer">
              <InstagramImg color="#0F0E2C" />
            </a>
          </SNS>
          <Copyright className="pc">
            <a
              href={URL.TERMS_OF_SERVICE}
              target="_blank"
              rel="noopener noreferrer"
            >
              이용약관
            </a>
            <a
              href={URL.PRIVACY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              개인정보처리방침
            </a>
            <a
              href={"mailto:" + URL.MAIL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>문의하기</u>
            </a>
          </Copyright>
        </About>
        <Links>
          <div>
            <p>About</p>
            <Link to="/about">서비스 소개</Link>
            <a href={URL.HISTORY} target="_blank" rel="noopener noreferrer">
              히스토리
            </a>
          </div>
          <div>
            <p>Resources</p>
            <Link to="/templates">템플릿</Link>
            <Link to="/community">커뮤니티</Link>
          </div>
        </Links>
      </div>
      <Copyright className="mobile">
        <a
          href={URL.TERMS_OF_SERVICE}
          target="_blank"
          rel="noopener noreferrer"
        >
          이용약관
        </a>
        <a href={URL.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
          개인정보처리방침
        </a>
        <a
          href={"mailto:" + URL.MAIL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>문의하기</u>
        </a>
      </Copyright>
      <CompanyInfo>
        <span>VALOY(발로이)</span>
        <span>사업자번호: 564-09-00870</span>
        <span>대표: 권해림, 김지수</span>
        <span>help@whattosay.kr</span>
      </CompanyInfo>
      <Copyright>
        <p>© 2020-{new Date().getFullYear()} VALOY ALL RIGHTS RESERVED</p>
      </Copyright>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 22.75rem;
  background: #f8f8ff;
  padding: 0 5.75rem;
  color: #8b95a1;
  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: solid 0.0625rem #f3f3f3;
    margin-bottom: 1.5rem;
    @media (max-width: 800px) {
      flex-direction: column;
      padding-bottom: 24px;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 800px) {
    height: auto;
    padding: 0 2rem 70px;
    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`
const About = styled.div`
  margin-top: 2.8rem;
  width: 16.375rem;

  @media (max-width: 800px) {
    margin-top: 47.5px;
    width: auto;
    word-break: keep-all;
  }
`
const SNS = styled.div`
  margin: 0.94rem 0;
  @media (max-width: 800px) {
    display: none;
  }
  &:hover {
    color: #5551ff;
    svg {
      fill: #5551ff;
    }
  }
`
const Desc = styled.p`
  margin-top: 0.5rem;
  color: #0f0e2c;
  font-size: 0.875rem;
  line-height: 140%;
  word-break: keep-all;
  a {
    display: inline-block;
    margin-left: 6px;
    text-decoration: underline;
    :hover {
      color: #5551ff;
    }
  }
  @media (max-width: 800px) {
    br {
      display: none;
    }
  }
`
const Links = styled.div`
  display: flex;
  margin-top: 2.81rem;
  gap: 2.5rem;
  color: #181433;
  > div {
    p {
      margin-bottom: 0.5rem;
      font-weight: 700;
      line-height: 1.5rem;
    }
    a {
      display: block;
      margin-bottom: 0.25rem;
      font-size: 0.875rem;
      line-height: 1.5rem;
      &:hover {
        color: #5551ff;
      }
    }
  }
  @media (max-width: 800px) {
    margin-top: 24px;
  }
`
const CompanyInfo = styled.p`
  margin-bottom: 2px;
  font-size: 0.75rem;
  letter-spacing: 0.03em;
  color: #acacb1;
  line-height: 150%;
  white-space: nowrap;
  word-break: keep-all;
  span {
    display: inline-block;
    margin-right: 8px;
  }
  @media (max-width: 800px) {
    white-space: pre-line;
    text-align: center;
    margin-top: 24px;
  }
`
const Copyright = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  letter-spacing: 0.03em;
  color: #acacb1;
  line-height: 150%;
  a {
    margin-right: 8px;
    &:hover {
      color: #5551ff;
    }
  }
  > p {
    margin-right: 10px;
  }
  @media (max-width: 800px) {
    left: 0;
    right: 0;
    bottom: 47.5px;
    text-align: center;
    > p {
      margin: 8px auto 0;
      text-align: center;
    }
  }
`

export default Footer
