import styled from "styled-components"

export const TextButton = styled.div`
  width: 293px;
  height: 51px;
  margin: 0 auto;
  border: 1px solid #000000;
  border-radius: 26px;
  font-weight: bold;
  font-size: 18px;
  line-height: 51px;
  text-align: center;
  color: #000000;
  cursor: pointer;
`

export const Badge = styled.div`
  display: inline-block;
  height: 28px;
  margin-right: 2rem;
  margin-bottom: 4px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 28px;
  color: #ffffff;
  border-radius: 8px;
`

export const SectionTitle = styled.p`
  color: #acacac;
  font-family: "Space Grotesk", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 1.125rem */
`
