import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
  isLoading: true
}

export const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    signIn: (_, action) => {
      const { authProvider, uid } = action.payload
      if (authProvider === "naver") {
        localStorage.setItem("nid", uid)
      }
      return { ...action.payload, isLoading: false }
    },
    signOut: () => {
      localStorage.clear()
      return { isLoading: false }
    },
    update: (state, action) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { signIn, signOut, update } = userSlice.actions

export default userSlice.reducer
