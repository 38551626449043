import React from 'react'
import styled, {keyframes} from 'styled-components'

export default function Reviews() {

  return <Container>
    <p>뭐라고할까는 <br className='md:hidden'/>여러분의 응원을 먹고 자랐어요.</p>
    <Slider>
      <Slide>
        <p>“가끔 메일 멘트가 너무 고민될 때 너무너무 유용합니다!”</p>
      </Slide>
      <Slide>
        <p>“정말 감사합니다!.!!!!! 너무 유용하게 쓰고 있습니다<br/>ㅜ.ㅜ. 제발 계속 서비스해주세요!”</p>
      </Slide>
      <Slide>
        <p>“감사합니다! 부고나 경조사에 쓸 메일 예시본을 찾고 있었는데 너무 잘 나와있어서 도움 많이 됐어요! 개발자님들 그외 다른 담당자분들 이런 유용한 페이지 만들어주셔서 정말 감사합니다!”</p>
      </Slide>
      <Slide>
        <p>“영어템플릿이 더 있었으면 좋겠어요 :) 감사합니다~ 외노자에게 빛과소금같은 사이트입니다!🙏”</p>
      </Slide>
      <Slide>
        <p>“이런 서비스 제공해주셔서 너무 감사합니다ㅠㅠ 진짜 유용하게 잘쓰고있어요!”</p>
      </Slide>
    </Slider>
  </Container>
}

const Slider = styled.div`
  padding: 42px 0;
  white-space: nowrap;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    padding: 32px 0;
  }
`

const slideLeftAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30rem);
  }
`

const Slide = styled.div`
  width: 30rem;
  height: 180px;
  display: inline-flex;
  padding: 2rem 2.5rem 1.5rem;
  margin-right: 2.5rem;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1.25rem;
  background: #FFF;
  font-weight: 500;
  color: #262626;
  font-size: 1.125rem;
  line-height: 1.75rem; 
  animation: ${slideLeftAnimation} 20s linear infinite;
  box-shadow: 0px 8px 12px 0px rgba(5, 7, 66, 0.06), 0px 4px 48px 0px rgba(5, 7, 66, 0.12);
  p {
    word-break: keep-all;
    white-space: pre-wrap;
  }
  span {
    margin-left: auto;
    color: #8E98A4;
  }
  @media screen and (max-width: 800px) {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 24px 32px;
    width: 312px;
    height: 184px;
    span {
      font-size: 0.875rem;
    }
  }
`

const Container = styled.div`
  position: relative;
  padding: 95px 0 52px;

  svg {
    position: absolute;
    top: -6.96rem;
    left: 50%;
    transform: translateX(-50%);
  }
  >p {
    text-align: center;
    color: #5B5454;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 120%;
  }

  @media screen and (max-width: 800px) {
    padding: 72px 0 40px;
    >p {
      font-size: 1rem;
    }
  }
`