import React from "react"
import { ReactComponent as ShareImg } from "../images/share.svg"
import { share } from "../common/utils"
import { logUserEvent } from "../common/firebase.utils"

export default function Share() {
  const onClickShare = () => {
    share()
    logUserEvent("share", { location: "main" })
  }

  return (
    <div className="pt-[40px] pb-[80px] text-center mx-auto w-[387px] max-w-[90vw]">
      <ShareImg className="mx-auto" />
      <p className="text-center mt-6 break-keep text-[#373F41] text-[18px] leading-[24px]">
        모두가 편하게 소통하는 그 날까지! 뭐라고할까가 필요할 친구와 동료들에게
        많이 알려주세요.
      </p>
      <button
        onClick={onClickShare}
        className="w-full border-black mt-[32px] text-[18px] font-bold border rounded-[26px] text-center leading-[51px]"
      >
        사이트 공유할래요
      </button>
    </div>
  )
}
