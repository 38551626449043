import React from "react"
import { useHistory } from "react-router-dom"
import styled, { keyframes } from "styled-components"
import { logUserEvent } from "../common/firebase.utils"

const situations = [
  [
    "입사 인사",
    "메일 제목",
    "부고 위로",
    "응원 인사",
    "고객 응대",
    "검토 요청",
    "채용 결과 통보",
    "면접 거절",
    "조의/조문 위로",
    "담당자 변경 안내",
    "퇴사 인사",
    "생일 축하",
    "회의 공지",
    "건배사",
    "계절 인사"
  ],
  [
    "아침 인사",
    "결혼 축하",
    "명절 인사",
    "퇴사응원",
    "병문안 위로 인사",
    "업무요청",
    "새해 인사",
    "연말 인사",
    "크리스마스 인사",
    "부재 공유",
    "조문 답례",
    "요청 회신",
    "저녁인사",
    "명언",
    "재촉 / 리마인더"
  ],
  [
    "추석 인사",
    "끝인사",
    "감사 인사",
    "입사 거절",
    "업무 보고",
    "자료 전달",
    "월요일 인사",
    "개그드립",
    "첫인사",
    "겨울 인사",
    "연차 신청 / 근무 조정",
    "퇴사 답장",
    "코로나 안부",
    "날씨 인사"
  ]
]

export default function Situations() {
  const history = useHistory()

  const onClickSituation = situation => {
    logUserEvent("click_landing_situation", { situation })
    history.push(`templates?situation=${situation}`)
  }

  return (
    <div className="pt-[56px] md:pt-[80px] pb-[56px]">
      <p className="text-center text-[24px] md:text-[32px] text-[#5B5454] mb-[40px]">
        실생활에서 바로 사용할 수 있는 <br className="md:hidden" />
        <b>
          <span className="text-primary">700여가지의</span> 초현실 상황들
        </b>
      </p>
      <RowsContainer className="overflow-hidden">
        {situations.map((situation, i) => (
          <div
            key={i}
            className="flex justify-center mb-[24px] gap-6 text-[#B7BEC6] text-[21px]"
          >
            {situation.map((item, j) => (
              <div
                key={j}
                onClick={() => onClickSituation(item)}
                className="cursor-pointer hover:bg-[#0F0E2C] hover:text-white shrink-0 py-2 px-6 rounded-[120px] border border-[#CBCCCE] bg-[#fbfbfa] leading-snug"
              >
                {item}
              </div>
            ))}
          </div>
        ))}
      </RowsContainer>
    </div>
  )
}

const slideLeftAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30%);
  }
`

const slideRightAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(30%);
  }
`

const RowsContainer = styled.div`
  > :nth-child(2n) {
    animation: ${slideLeftAnimation} 40s alternate linear infinite;
  }
  > :nth-child(2n + 1) {
    animation: ${slideRightAnimation} 40s alternate linear infinite;
  }
  @media (max-width: 800px) {
    > * {
      animation-duration: 15s !important;
    }
  }
`
