import React, { useState, useRef } from "react"
import styled from "styled-components"
import { COMMUNITY_CATEGORIES } from "../../common/constants"
import { MIN_TEMPLATE_LENGTH, MAX_COMMENT_LENGTH } from "../../common/constants"
import { db, fieldValue, logUserEvent } from "../../common/firebase.utils"
import { detectLanguage, hasRestrictedWords } from "../../common/utils"
import { useUserInfo } from "../../hooks/useUserInfo"
import validate from "../../write/contentValidator"
import Checkbox from "../../common/Checkbox"

function CommentWrite({ categoryId, postId, situation, requestLogin }) {
  const [comment, setComment] = useState("")
  const [registerTemplate, setRegisterTemplate] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)
  const textareaRef = useRef()
  const { userId, nickname } = useUserInfo()

  const onClickRegister = async () => {
    if (isRegistering) {
      return
    }
    const writerId = userId
    if (!writerId) {
      return requestLogin()
    }

    setIsRegistering(true)

    const trimmedComment = comment.trim()
    if (registerTemplate) {
      const message = await validate(trimmedComment)
      if (message) {
        setIsRegistering(false)
        return alert(message)
      }
    } else {
      if (!registerable()) {
        setIsRegistering(false)
        return alert("댓글을 입력해주세요.")
      }
      if (comment.length > MAX_COMMENT_LENGTH) {
        setIsRegistering(false)
        return alert(
          `댓글은 최대 ${MAX_COMMENT_LENGTH}자까지 등록 가능합니다.\n(현재 ${comment.length}자)`
        )
      }
      const includedBadWord = await hasRestrictedWords(comment)
      if (includedBadWord) {
        setIsRegistering(false)
        return alert(
          `부적절한 단어 '${includedBadWord}'을(를) 포함하고 있습니다.`
        )
      }
    }

    const _comment = {
      targetType: "posts",
      targetId: postId,
      targetCommentId: null,
      content: trimmedComment,
      writerId,
      likeReactionCount: 0,
      reportCount: 0,
      createdat: fieldValue.serverTimestamp(),
      updatedat: null,
      deletedat: null
    }
    db.collection("comments")
      .add(_comment)
      .then(() => {
        setComment("")
        scrollToBottom()
        if (registerTemplate) {
          registerAsTemplate(_comment)
        } else {
          setIsRegistering(false)
        }
      })
      .catch(() => setIsRegistering(false))
  }

  const registerAsTemplate = comment => {
    db.collection("situations")
      .where("title", "==", situation)
      .get()
      .then(snap => {
        if (snap.size > 0) {
          const template = {
            writerid: comment.writerId,
            writernick: nickname,
            createdat: fieldValue.serverTimestamp(),
            deletedat: null,
            situationid: snap.docs[0].id,
            situation: situation,
            content: comment.content,
            pasted: 0,
            reported: false,
            language: detectLanguage(comment.content),
            length: comment.content.length
          }
          db.collection("templates")
            .add(template)
            .then(() => {
              setIsRegistering(false)
              logUserEvent("add_template_from_comment", {
                situation
              })
            })
            .catch(() => setIsRegistering(false))
        } else {
          window.alert(
            `${situation} 상황이 존재하지 않아 템플릿 등록은 불가능합니다.`
          )
          setIsRegistering(false)
        }
      })
      .catch(e => {
        setIsRegistering(false)
      })
  }

  const scrollToBottom = () => {
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    )
  }

  const resizeTextarea = () => {
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
  }

  const getCommentPlaceholder = () => {
    const commentPlaceholder = COMMUNITY_CATEGORIES.find(
      category => category.id === categoryId
    )?.commentPlaceholder
    return commentPlaceholder || "댓글을 입력해주세요."
  }

  const registerable = () =>
    registerTemplate
      ? comment.trim().length >= MIN_TEMPLATE_LENGTH
      : comment.trim().length > 0

  return (
    <CommentWriteContainer>
      <div className="write-area">
        <textarea
          ref={textareaRef}
          placeholder={getCommentPlaceholder()}
          className="no-scrollbar"
          onKeyDown={resizeTextarea}
          onKeyUp={resizeTextarea}
          rows={1}
          value={comment}
          onChange={({ target }) => setComment(target.value)}
          maxLength={MAX_COMMENT_LENGTH}
        />
      </div>
      {/* {categoryId === "templates" && (
        <div className="register-template">
          <input
            type="checkbox"
            id="register"
            checked={registerTemplate}
            onChange={() => setRegisterTemplate(!registerTemplate)}
          />
          <label htmlFor="register">템플릿으로도 등록할래요</label>
        </div>
      )} */}
      <div
        className={`register-button active--${registerable()}`}
        onClick={onClickRegister}
      >
        {isRegistering ? "등록 중" : "등록"}
      </div>
    </CommentWriteContainer>
  )
}

const CommentWriteContainer = styled.div`
  min-height: 102px;
  position: relative;
  .write-area {
    padding: 1rem 24px 66px;
    height: auto;
    textarea {
      color: #121212;
      font-size: 14px;
      line-height: 20px;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      border: none;
      outline: none;
      overflow: auto;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      resize: none;
      ::placeholder {
        color: #c4c4c4;
      }
    }
  }
  .register-template {
    position: absolute;
    left: 25px;
    bottom: 17px;
  }
  .register-button {
    position: absolute;
    width: 58px;
    height: 40px;
    line-height: 41px;
    text-align: center;
    right: 24px;
    bottom: 16px;
    background: #ebebeb;
    border-radius: 5.05699px;
    font-size: 14px;
    color: #ffffff;
    cursor: pointer;
    &.active--true {
      color: #ffffff;
      background: #5551ff;
    }
  }
  @media (max-width: 800px) {
    margin-bottom: 16px;
  }
`

export default CommentWrite
