import React from "react"
import styled, { css } from "styled-components"
import { ReactComponent as LikeImg } from "../../images/reaction-like.svg"
import { ReactComponent as WowImg } from "../../images/reaction-wow.svg"
import { ReactComponent as SadImg } from "../../images/reaction-sad.svg"
import { ReactComponent as HmmImg } from "../../images/reaction-hmm.svg"
import { COMMUNITY_REACTIONS } from "../../common/constants"

function ReactionIcon({ reaction, count, selected, onClick }) {
  const getImg = () => {
    switch (reaction) {
      case COMMUNITY_REACTIONS.LIKE:
        return <LikeImg fill={selected ? "#5551FF" : "#E6E6E6"} />
      case COMMUNITY_REACTIONS.WOW:
        return <WowImg fill={selected ? "#5551FF" : "#E6E6E6"} />
      case COMMUNITY_REACTIONS.SAD:
        return <SadImg fill={selected ? "#5551FF" : "#E6E6E6"} />
      case COMMUNITY_REACTIONS.HMM:
        return (
          <HmmImg
            fill={selected ? "#5551FF" : "#E6E6E6"}
            stroke={selected ? "white" : "black"}
          />
        )
      default:
        return null
    }
  }

  return (
    <Container onClick={onClick} clickable={onClick != null}>
      {getImg()}
      <Count selected={selected}>{count}</Count>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}
  @media (max-width: 800px) {
    height: 94.42px;
    svg {
      width: 70px;
      margin-top: -12px;
    }
  }
`
const Count = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 6px;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  font-weight: 400;
  font-size: 10px;
  ${({ selected }) =>
    selected
      ? css`
          background: #4945d2;
          color: #ffffff;
        `
      : css`
          background: #f7f7f7;
          color: #cccccc;
        `};
  @media (max-width: 800px) {
    right: unset;
    top: unset;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    line-height: unset;
    height: auto;
    padding: 0;
    background: none;
    font-weight: ${({ selected }) => (selected ? 500 : 400)};
    font-size: 12px;
    color: ${({ selected }) => (selected ? "#5551ff" : "#C4C4C4")};
  }
`

export default ReactionIcon
