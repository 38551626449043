import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { logUserEvent } from "../common/firebase.utils"
import { ReactComponent as CloseButton } from "../images/close-gray.svg"
import { ReactComponent as HelpedIcon } from "../images/clapping.svg"
import { useUserInfo } from "../hooks/useUserInfo"
import confetti from "canvas-confetti"

function HelpfulPopup({ copyCount, fromLastLogin, onCloseClick }) {
  const history = useHistory()
  const { nickname } = useUserInfo()

  useEffect(() => {
    const confettiOptions = {
      spread: 70,
      origin: { y: 0.6 }
    }

    confetti(confettiOptions)
    const interval = setInterval(() => {
      confetti(confettiOptions)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const onClickRegisterNow = () => {
    logUserEvent("click_write_on_helpful_popup")
    history.push("/write")
    onCloseClick()
  }

  return (
    <Background>
      <Container>
        <CloseButton id="close_button" onClick={onCloseClick} />
        <Content>
          <span>
            <HelpedIcon width={48} height={48} />
          </span>
          <p>공유해주신 템플릿이 사랑받고 있어요.</p>
          <p>
            {fromLastLogin ? "지난 로그인 이후" : "지금까지"} {nickname}님의
            템플릿이 <b>{copyCount.toLocaleString()}회</b> 사용되었어요.
            감사합니다!
          </p>
        </Content>
        <Bottom>
          <button onClick={onClickRegisterNow}>
            도움이 될 템플릿이 더 있어요
          </button>
          <button onClick={onCloseClick}>확인</button>
        </Bottom>
      </Container>
    </Background>
  )
}

const Container = styled.div`
  position: relative;
  width: 480px;
  height: 262px;
  background: #f9f9f9;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  #close_button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1;
    opacity: 0.35;
    cursor: pointer;
  }
  @media (max-width: 800px) {
    max-width: 95%;
  }
`
const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
  padding: 1rem;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > p {
    word-break: keep-all;
  }
  > p:first-of-type {
    margin-top: 24px;
    font-family: "GmarketSansBold";
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
  }
  > p:last-of-type {
    margin-top: 0.5rem;
    font-size: 14px;
    line-height: 16px;
    b {
      font-weight: 700;
    }
  }
`
const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f4f4f4;
  border-top: 1px solid #ebebeb;
  > :first-child {
    font-size: 12px;
    text-decoration: underline;
    color: #8b95a1;
  }
  > :last-child {
    width: 92px;
    line-height: 44px;
    text-align: center;
    background: #5551ff;
    border-radius: 4px;
    color: #ffffff;
  }
  @media (max-width: 800px) {
    padding-right: 0.5rem;
  }
`

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`

export default HelpfulPopup
