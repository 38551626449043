import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { db } from "../../common/firebase.utils"
import ProfilePicture from "../../common/ProfilePicture"
import { getPastTimeText } from "../../common/utils"

function ProfileAndTime({
  userId,
  timestamp,
  pictureSize = "32px",
  isUpdated,
  hideProfileOnMobile
}) {
  const [writer, setWriter] = useState({})

  useEffect(() => {
    if (userId) {
      db.collection("users")
        .doc(userId)
        .get()
        .then(doc => {
          if (doc.exists) {
            setWriter(doc.data())
          } else {
            setWriter({
              nickname: "계정을 삭제한 사용자"
            })
          }
        })
        .catch(e => {
          setWriter({
            nickname: "계정을 불러올 수 없습니다"
          })
        })
    }
  }, [userId])

  return (
    <Container height={pictureSize} hideProfileOnMobile={hideProfileOnMobile}>
      <ProfilePicture className="photo" src={writer.photo} size={pictureSize} />
      <span className="nick">{writer.nickname}</span>
      {timestamp && (
        <span className="date">
          | {getPastTimeText(timestamp)} {isUpdated && "(수정됨)"}
        </span>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: ${({ height }) => height};
  .photo {
    margin-right: 10px;
  }
  .nick {
    font-weight: 500;
  }
  .date {
    margin-left: 4px;
    font-weight: 350;
    color: #bfbfbf;
  }
  @media (max-width: 800px) {
    line-height: ${({ hideProfileOnMobile, height }) =>
      hideProfileOnMobile ? "unset" : height};
    .photo {
      display: ${({ hideProfileOnMobile }) =>
        hideProfileOnMobile ? "none" : "inherit"};
    }
  }
`

export default ProfileAndTime
