import React, { useState, useRef, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"
import compression from "browser-image-compression"
import SituationSelect from "../common/SituationSelect"
import AuthManager from "../common/AuthManager"
import { COMMUNITY_CATEGORIES } from "../common/constants"
import { ReactComponent as AttachIcon } from "../images/attach-image.svg"
import { ReactComponent as AttachDeleteIcon } from "../images/attach-delete.svg"
import { db, storage, fieldValue } from "../common/firebase.utils"
import { hasRestrictedWords } from "../common/utils"
import { useUserInfo } from "../hooks/useUserInfo"

export const MIN_CONTENT_LENGTH = 15
const MAX_CONTENT_LENGTH = 1000
const MAX_TITLE_LENGTH = 30
const MAX_ATTACH_COUNT = 5
const MAX_ATTACH_SIZE = 2

function Write({ categoryId }) {
  const history = useHistory()
  const location = useLocation()
  const isEdit = location.state?.post !== undefined
  const savedPost = isEdit
    ? location.state.post
    : localStorage.getItem("writingPost")
    ? JSON.parse(localStorage.getItem("writingPost"))
    : {
        situation: null,
        title: "",
        content: "",
        images: []
      }
  const { userId } = useUserInfo()
  const [situation, setSituation] = useState(savedPost.situation)
  const [title, setTitle] = useState(savedPost.title)
  const [content, setContent] = useState(savedPost.content)
  const [images, setImages] = useState(savedPost.images)
  const [isUploading, setIsUploading] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)
  const isTemplatesCategory = categoryId === "templates"

  useEffect(() => {
    if (!isEdit) {
      localStorage.setItem(
        "writingPost",
        JSON.stringify({
          situation,
          title,
          content,
          images
        })
      )
    }
  }, [situation, title, content, images])

  useEffect(() => {
    setIsUploading(false)
  }, [images])

  const registerable = () => {
    if (title.length > 0 && content.length >= MIN_CONTENT_LENGTH) {
      if (isTemplatesCategory) {
        return situation != null
      } else {
        return true
      }
    }
    return false
  }

  const onClickRegister = async () => {
    const writerid = userId
    if (!writerid) {
      alert("로그인 정보가 없습니다. 재로그인 부탁드립니다.")
      return
    }
    if (content.length < MIN_CONTENT_LENGTH) {
      alert(`내용을 ${MIN_CONTENT_LENGTH}자 이상 입력해주세요.`)
      return
    }
    if (!registerable()) {
      alert("모든 항목을 입력해주세요.")
      return
    }
    if (title.length > MAX_TITLE_LENGTH) {
      alert(
        `제목은 최대 ${MAX_TITLE_LENGTH}자까지 등록 가능합니다.\n(현재 ${title.length}자)`
      )
      return
    }
    if (content.length > MAX_CONTENT_LENGTH) {
      alert(
        `내용은 최대 ${MAX_CONTENT_LENGTH}자까지 등록 가능합니다.\n(현재 ${content.length}자)`
      )
      return
    }
    setIsRegistering(true)
    let includedBadWord = await hasRestrictedWords(title)
    if (!includedBadWord) {
      includedBadWord = await hasRestrictedWords(content)
    }
    if (includedBadWord) {
      alert(`부적절한 단어 '${includedBadWord}'을(를) 포함하고 있습니다.`)
      setIsRegistering(false)
      return
    }
    const post = {
      situation,
      title,
      content,
      images
    }
    if (isEdit) {
      post.updatedat = fieldValue.serverTimestamp()
      db.collection("posts")
        .doc(savedPost.id)
        .update(post)
        .then(() => {
          history.replace(`/community/${categoryId}/${savedPost.id}`)
        })
        .catch(() => setIsRegistering(false))
    } else {
      db.collection("posts")
        .add({
          ...post,
          writerid,
          category: categoryId,
          viewCount: 0,
          commentCount: 0,
          totalReactionCount: 0,
          reportCount: 0,
          createdat: fieldValue.serverTimestamp(),
          updatedat: null,
          deletedat: null,
          deleterequestedat: null
        })
        .then(() => {
          localStorage.removeItem("writingPost")
          history.replace(`/community/${categoryId}`)
        })
        .catch(() => setIsRegistering(false))
    }
  }

  const compressImage = async image => {
    try {
      const options = {
        maxSizeMB: 1
      }
      return await compression(image, options)
    } catch (e) {
      console.log(e)
    }
  }

  const onSelectImage = async ({ target }) => {
    const file = target.files[0]
    if (!file) {
      return
    }
    if (images.length === MAX_ATTACH_COUNT) {
      alert(`최대 ${MAX_ATTACH_COUNT}개까지 첨부 가능합니다.`)
      return
    }
    if (file.size > MAX_ATTACH_SIZE * 1024 * 1024) {
      alert(`파일 크기가 ${MAX_ATTACH_SIZE}MB를 넘습니다.`)
      return
    }
    setIsUploading(true)
    const compressedFile = file.type.includes("gif")
      ? file
      : await compressImage(file)
    const fileName = `${userId}_${new Date().valueOf()}`
    const ref = storage.ref().child(`community/${fileName}`)
    ref
      .put(compressedFile)
      .then(snapshot => {
        snapshot.ref.getDownloadURL().then(url => {
          setImages([...images, url])
        })
      })
      .catch(() => setIsUploading(false))
  }

  const onClickDeleteImage = index => {
    if (window.confirm("해당 이미지를 지우시겠습니까?")) {
      const ref = storage.refFromURL(images[index])
      const _images = [...images]
      _images.splice(index, 1)
      setImages(_images)
      ref.delete()
    }
  }

  const getPlaceholder = () => {
    const categoryPlaceholder = COMMUNITY_CATEGORIES.find(
      category => category.id === categoryId
    )?.writePlaceholder
    return (
      (categoryPlaceholder ? `${categoryPlaceholder}\n` : "") +
      "* 저작권 침해나 유해물로 판단되는 경우 경고 없이 삭제됩니다."
    )
  }

  const attached = images.length > 0

  return (
    <Container>
      <AuthManager
        onLogout={() => {
          history.replace("/community")
        }}
      />
      <Header>
        {isTemplatesCategory && (
          <div>
            <SituationSelect
              className="situation-select"
              selected={situation}
              onSelect={setSituation}
              placeholder="어떤 주제에 가까운가요?"
              useShowAll={false}
            />
          </div>
        )}
        <input
          type="text"
          value={title}
          maxLength={MAX_TITLE_LENGTH}
          onChange={({ target }) => setTitle(target.value)}
          placeholder="제목을 입력해주세요"
        />
      </Header>
      <Content attached={attached}>
        <textarea
          className="no-scrollbar"
          placeholder={getPlaceholder()}
          value={content}
          maxLength={MAX_CONTENT_LENGTH}
          onChange={({ target }) => setContent(target.value)}
        ></textarea>
        <ImageAttach>
          <input
            type="file"
            id="attach"
            accept="image/png, image/gif, image/jpeg"
            disabled={isUploading || images.length === MAX_ATTACH_COUNT}
            onChange={onSelectImage}
          />
          <label htmlFor="attach">
            {!attached && <AttachIcon />}
            <p>
              {isUploading ? "사진 첨부 중" : "사진 첨부"}
              {!attached &&
                ` (최대 ${MAX_ATTACH_COUNT}개/ ${MAX_ATTACH_SIZE}MB 이하)`}
            </p>
            {attached && <span className="image-count">{images.length}</span>}
          </label>
          {attached && (
            <div className="images">
              {images.map((image, i) => (
                <div key={i} onClick={() => onClickDeleteImage(i)}>
                  <img src={image} />
                  <AttachDeleteIcon />
                </div>
              ))}
            </div>
          )}
        </ImageAttach>
      </Content>
      <RegisterButton registerable={registerable()}>
        <p>뭐라고할까 커뮤니티에 참여해주셔서 감사합니다.</p>
        <button onClick={isRegistering ? null : onClickRegister}>
          {isRegistering ? "등록 중" : "등록하기"}
        </button>
      </RegisterButton>
    </Container>
  )
}

const Container = styled.div`
  padding: 24px 48px;
  max-width: 960px;
  @media (max-width: 800px) {
    padding: 0;
  }
`

const Header = styled.div`
  display: flex;
  margin-bottom: 1rem;
  > div:first-child {
    width: 292px;
    margin-right: 16px;
    height: 48px;
  }
  > input {
    height: 48px;
    outline: none;
    -webkit-appearance: none;
    padding: 0 2rem;
    font-size: 18px;
    color: black;
    flex: 1;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 10px;
    &::placeholder {
      color: #d4d4d4;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 0;
    > div:first-child {
      width: 100%;
      margin-right: 0;
      .situation-select {
        > * {
          border-left: none;
          border-top: none;
          border-right: none;
        }
      }
    }
    > input {
      border-radius: 0;
      border-top: none;
      flex: unset;
      padding: 0 24px;
    }
  }
`

const Content = styled.div`
  border: 1px solid ${props => (props.highlight ? "#5551FF" : "#EBEBEB")};
  border-radius: 10px;
  textarea {
    box-sizing: border-box;
    width: 100%;
    height: ${({ attached }) => (attached ? "294px" : "472px")};
    padding: 24px 32px;
    border: none;
    outline: none;
    overflow: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    font-size: 14px;
    line-height: 20px;
    background: none;
    &::placeholder {
      color: #d4d4d4;
    }
  }
  @media (max-width: 800px) {
    border: none;
    border-bottom: 1px solid #ebebeb;
    border-radius: 0;
    textarea {
      padding: 16px 24px;
      font-size: 14px;
      height: ${({ attached }) => (attached ? "222px" : "320px")};
    }
  }
`

const ImageAttach = styled.div`
  min-height: 49px;
  border-top: 1px solid #ebebeb;
  padding: 0 32px;
  input {
    display: none;
  }
  label {
    display: inline-flex;
    height: 49px;
    padding-top: 10px;
    cursor: pointer;
    svg {
      margin-right: 8px;
    }
    p {
      line-height: 24px;
      font-size: 14px;
      color: #979797;
    }
    .image-count {
      display: inline-block;
      margin-left: 2px;
      width: 24px;
      height: 24px;
      line-height: 24px;
      background: #c4c4c4;
      border-radius: 30px;
      font-size: 10px;
      text-align: center;
      color: #ffffff;
    }
  }
  .images {
    display: flex;
    margin-bottom: 24px;
    > div {
      width: 80px;
      height: 80px;
      margin-right: 16px;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      svg {
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
  @media (max-width: 800px) {
    padding: 0 24px;
    label {
      height: 44px;
    }
  }
`

const RegisterButton = styled.div`
  text-align: center;
  margin-top: 24px;
  margin-bottom: 40px;
  p {
    font-size: 12px;
    color: #979797;
  }
  button {
    width: 200px;
    height: 51px;
    margin-top: 1rem;
    font-size: 12px;
    line-height: 100%;
    color: #ffffff;
    background: ${({ registerable }) => (registerable ? "#5551ff" : "#bdbdbd")};
    border: none;
    border-radius: 25.5px;
    cursor: pointer;
  }
`

export default Write
