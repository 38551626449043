import React from 'react'
import Gnb from './Gnb'
import Footer from './Footer'
import GoToTop from './GoToTop'
import styled from 'styled-components'

function PageLayout({
  clearGnbOnTop = false,
  gnbType = "default",
  children,
  hasFloatingButton = false,
  backgroundColor = "transparent"
}) {
  return (
    <Container backgroundColor={backgroundColor}>
      <Gnb clearOnTop={clearGnbOnTop} type={gnbType} />
      {children}
      <Footer />
      <GoToTop aboveOtherButton={hasFloatingButton} />
    </Container>
  )
}

const Container = styled.div`
  min-height: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
`

export default PageLayout
