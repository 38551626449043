import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./slices/userSlice"
import layerReducer from "./slices/layerSlice"

export default configureStore({
  reducer: {
    user: userReducer,
    layer: layerReducer
  }
})
