import React, { useEffect, useState } from "react"
import styled from "styled-components"
import moment from "moment"
import defaultImg from "../images/profile-default.svg"
import { ReactComponent as CommentIcon } from "../images/comment.svg"
import { ReactComponent as ViewIcon } from "../images/view.svg"
import likeReactionImg from "../images/reaction-mini-like.svg"
import wowReactionImg from "../images/reaction-mini-wow.svg"
import sadReactionImg from "../images/reaction-mini-sad.svg"
import hmmReactionImg from "../images/reaction-mini-hmm.svg"
import { db } from "../common/firebase.utils"
import { REPORT_HIDE_COUNT, COMMUNITY_REACTIONS } from "../common/constants"

const REACTION_IMGS = {
  [COMMUNITY_REACTIONS.HMM]: hmmReactionImg,
  [COMMUNITY_REACTIONS.LIKE]: likeReactionImg,
  [COMMUNITY_REACTIONS.SAD]: sadReactionImg,
  [COMMUNITY_REACTIONS.WOW]: wowReactionImg
}

function CommunityItem({ post, onClickPost, isNotice, hideWriter }) {
  const [writer, setWriter] = useState(hideWriter ? undefined : {})
  const hideByReport = post?.reportCount >= REPORT_HIDE_COUNT

  useEffect(() => {
    if (!hideWriter && post.writerid) {
      db.collection("users")
        .doc(post.writerid)
        .get()
        .then(doc => {
          if (doc.exists) {
            setWriter(doc.data())
          } else {
            setWriter({
              nickname: "계정을 삭제한 사용자"
            })
          }
        })
        .catch(e => {
          setWriter({
            nickname: "계정을 불러올 수 없습니다"
          })
        })
    } else {
      setWriter()
    }
  }, [post])

  const getMostReactionImg = () => {
    let mostCount = 0
    let mostType = undefined
    const total = post.totalReactionCount
    for (const reactionType of Object.values(COMMUNITY_REACTIONS)) {
      const count = post[`${reactionType}ReactionCount`]
      if (count >= total / 2) {
        return REACTION_IMGS[reactionType]
      }
      if (count > mostCount) {
        mostCount = count
        mostType = reactionType
      }
    }
    return REACTION_IMGS[mostType]
  }

  return (
    <ItemContainer onClick={hideByReport ? null : () => onClickPost(post)}>
      <div className="upper">
        {writer && (
          <div className="writer">
            <img alt="profile" src={writer.photo || defaultImg} />
            <span className="nick">{writer.nickname}</span>
          </div>
        )}
        {post.type && <p className="type">{post.type}</p>}
        <span className="date">
          {moment(post.createdat.toDate()).format("YYYY.MM.DD")}
        </span>
      </div>
      {!hideByReport && (
        <div className="title">
          {post.situation && <p className="situation">{post.situation}</p>}
          <p className="title-text">{post.title}</p>
        </div>
      )}
      {!isNotice && (
        <p className={`content hidden--${hideByReport}`}>
          {hideByReport
            ? `${REPORT_HIDE_COUNT}회 이상 신고되어 숨겨진 글입니다.`
            : post.content}
        </p>
      )}
      <div className="info">
        <div className="left">
          {post.totalReactionCount > 0 && (
            <div className="reactions">
              <div
                style={{ backgroundImage: `url(${getMostReactionImg()})` }}
              />
              {post.totalReactionCount}
            </div>
          )}
          <div className="views">
            <ViewIcon />
            {post.viewCount}
          </div>
          {!isNotice && (
            <div className="comments">
              <CommentIcon />
              {post.commentCount}
            </div>
          )}
        </div>
      </div>
    </ItemContainer>
  )
}

const ItemContainer = styled.div`
  margin-bottom: 16px;
  height: auto;
  padding: 24px 24px 0;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  cursor: pointer;
  color: #121212;
  background: #ffffff;
  .upper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 12px;
    .writer {
      display: flex;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
        border-radius: 50%;
      }
      .nick {
        margin-right: 0.5rem;
      }
    }
    .type {
      font-size: 14px;
      color: #5551ff;
      margin-right: 8px;
    }
    .date {
      color: #bfbfbf;
    }
  }
  .title {
    display: flex;
    align-items: center;
    min-width: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    .situation {
      display: inline-block;
      flex-shrink: 0;
      height: 28px;
      line-height: 26px;
      padding: 0 12px;
      margin-right: 12px;
      font-size: 12px;
      color: #555555;
      background: #f7f8fa;
      border: 1px solid #e6e6e6;
      border-radius: 4px;
    }
    .title-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .content {
    width: 55%;
    height: 20px;
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.hidden--true {
      color: #bfbfbf;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    padding-bottom: 16px;
    svg {
      margin-right: 4px;
    }
    .left {
      display: flex;
      align-items: center;
      .reactions,
      .views,
      .comments {
        display: flex;
        align-items: center;
        margin-right: 10px;
        font-size: 12px;
      }
      .reactions div {
        box-sizing: border-box;
        background-size: 24px 24px;
        background-position: center;
        width: 24px;
        height: 24px;
        border: 1px solid #5551ff;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 8px;
    border-left: none;
    border-right: none;
    border-radius: 0;
    &:first-child {
      border-top: none;
    }
    .content {
      width: 81%;
    }
  }
`

export default CommunityItem
