import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import PageLayout from "../common/PageLayout"
import { TYPE as GNB_TYPE } from "../common/Gnb"
import List from "./List"
import Detail from "./detail"
import Write from "./Write"
import styled, { css } from "styled-components"
import { COMMUNITY_CATEGORIES, ORDER, ORDER_BY } from "../common/constants"
import NoticeDetail from "./NoticeDetail"
import moment from "moment"
import { logUserEvent, db } from "../common/firebase.utils"

const NEW_DAYS = 5

function Community({ match }) {
  const categories = COMMUNITY_CATEGORIES
  const DEFAULT_CATEGORY_ID = "templates"
  const { categoryId = DEFAULT_CATEGORY_ID, postId } = match.params
  const [category, setCategory] = useState(
    categories.find(c => c.id === categoryId)
  )
  const [hasNew, setHasNew] = useState({})
  const [banner, setBanner] = useState()

  const history = useHistory()
  const isWrite = postId === "write"
  const isMobile = window.innerWidth <= 800

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    for (const { id } of categories) {
      checkNewPosts(id)
    }
  }, [])

  useEffect(() => {
    if (category?.id !== categoryId) {
      setCategory(categories.find(c => c.id === categoryId))
    }
  }, [categoryId])

  const onClickCategory = cate => {
    if (cate.id !== categoryId) {
      setCategory(categories.find(c => c.id === cate.id))
      goToListFor(cate)
    }
  }

  const onClickBanner = () => {
    logUserEvent("banner_open_event")
    history.push(banner.link)
  }

  const goToListFor = _category => {
    history.push("/community/" + _category.id)
  }

  const checkNewPosts = categoryId => {
    if (categoryId === "notice") {
      return checkNewNotice()
    }
    db.collection("posts")
      .where("category", "==", categoryId)
      .where("deletedat", "==", null)
      .limit(1)
      .orderBy(ORDER_BY.CREATED_AT, ORDER.DESC)
      .get()
      .then(snapshot => {
        const latest = snapshot.size > 0 ? snapshot.docs[0].data() : null
        let _hasNew = false
        if (latest) {
          const noticeCreateMoment = moment(latest.createdat.toDate())
          _hasNew = moment().diff(noticeCreateMoment, "d") <= NEW_DAYS
        }
        setHasNew({ ...hasNew, [categoryId]: _hasNew })
      })
  }

  const checkNewNotice = () => {
    db.collection("notices")
      .where("hide", "==", false)
      .limit(1)
      .orderBy(ORDER_BY.CREATED_AT, ORDER.DESC)
      .get()
      .then(snapshot => {
        const latest = snapshot.size > 0 ? snapshot.docs[0].data() : null
        let _hasNew = false
        if (latest) {
          const noticeCreateMoment = moment(latest.createdat.toDate())
          _hasNew = moment().diff(noticeCreateMoment, "d") <= NEW_DAYS
        }
        setHasNew({ ...hasNew, notice: _hasNew })
      })
  }

  const getBodyContent = () => {
    if (postId) {
      return isWrite ? (
        <Write categoryId={categoryId} />
      ) : categoryId === "notice" ? (
        <NoticeDetail
          noticeId={postId}
          goToList={() => goToListFor(category)}
        />
      ) : (
        <Detail
          categoryId={categoryId}
          postId={postId}
          goToList={() => goToListFor(category)}
        />
      )
    }
    return <List category={category} banner={banner} />
  }

  const showheader = !postId || isWrite

  return (
    <PageLayout
      hasFloatingButton={isMobile && !postId}
      gnbType={isMobile && isWrite ? GNB_TYPE.BACK : undefined}
    >
      <Container>
        {showheader && (
          <Header
            isWrite={isWrite}
            categories={categories}
            categoryId={categoryId}
            category={category}
            onClickCategory={onClickCategory}
            postId={postId}
            hasNew={hasNew}
            banner={banner}
            onClickBanner={onClickBanner}
          />
        )}
        <Body>{getBodyContent()}</Body>
      </Container>
    </PageLayout>
  )
}

function Header({
  isWrite,
  postId,
  category,
  categoryId,
  categories,
  onClickCategory,
  hasNew,
  banner,
  onClickBanner
}) {
  const showBanner = banner && !isWrite
  return (
    <HeaderContainer forWrite={isWrite} showBanner={showBanner}>
      {showBanner && (
        <img
          className="banner"
          alt="banner"
          onClick={onClickBanner}
          src={banner.mobileImage}
        />
      )}
      <div className="titles">
        <p className="short-desc">{category.shortDescription}</p>
        <p className="title">
          {isWrite ? category.writeTitle || category.name : category.title}
        </p>
        {!isWrite && <p className="desc">{category.description}</p>}
      </div>
      {!postId && (
        <div className="categories">
          {categories.map((cate, i) => (
            <Category
              className={`selected--${cate.id === categoryId}`}
              key={i}
              onClick={() => onClickCategory(cate)}
            >
              {cate.name}
              {hasNew[cate.id] && <div className="new" />}
            </Category>
          ))}
        </div>
      )}
    </HeaderContainer>
  )
}

const Container = styled.div`
  width: 100%;
  padding-top: var(--gnb-height);
`
const HeaderContainer = styled.header`
  position: relative;
  height: ${({ forWrite }) => (forWrite ? "144px" : "282px")};
  padding-top: ${({ forWrite }) => (forWrite ? "48px" : "72px")};
  background: #fcfdff;
  border-bottom: 1px solid #ebebeb;
  text-align: center;
  .banner {
    display: none;
  }
  > div {
    max-width: 1138px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  .short-desc {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 16px;
    color: #5551ff;
  }
  .title {
    font-weight: 500;
    font-size: 32px;
  }
  .desc {
    margin-top: 1rem;
    padding-bottom: 55.5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    white-space: pre-wrap;
  }
  .categories {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 1138px;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 800px) {
    height: auto;
    padding-top: 0;

    .banner {
      display: block;
      width: 100%;
      cursor: pointer;
    }
    .titles {
      display: ${({ showBanner }) => (showBanner ? "none" : "block")};
      height: ${({ forWrite }) => (forWrite ? "102px" : "233px")};
      padding-top: ${({ forWrite }) => (forWrite ? "32px" : "45px")};
      .short-desc {
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 600;
      }
      .title {
        font-size: 18px;
      }
      .desc {
        padding-bottom: 44px;
        font-size: 12px;
      }
    }

    .categories {
      ${({ showBanner }) =>
        showBanner
          ? css`
              position: static;
              left: unset;
              transform: none;
            `
          : ""};
      padding: 0;
      border-top: 1px solid #ebebeb;
    }
  }
`
const Category = styled.div`
  position: relative;
  padding: 0 24px 17.5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  &.selected--true {
    font-weight: 500;
    border-bottom: 1px solid #333333;
  }
  .new {
    position: absolute;
    top: 0;
    right: calc(24px - 8px);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #5551ff;
  }
  @media (max-width: 800px) {
    height: 48px;
    line-height: 48px;
    .new {
      top: 15.5px;
    }
  }
`
const Body = styled.div`
  min-height: 55vh;
  > * {
    max-width: 1138px;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 800px) {
    > * {
      padding-left: 0;
      padding-right: 0;
    }
  }
`

export default Community
