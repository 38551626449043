import React from "react"
import styled from "styled-components"
import { ReactComponent as EmptyImg } from "../../images/comment-empty.svg"

function CommentEmpty() {
  return (
    <Container>
      <EmptyImg />
      <p>아직 댓글이 없어요! 첫 댓글을 남겨주세요.</p>
    </Container>
  )
}

const Container = styled.div`
  margin: 104px auto;
  text-align: center;
  border: none !important;
  svg {
    margin: 0 auto;
  }
  p {
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: #c4c4c4;
  }
  @media (max-width: 800px) {
    margin: 109px auto;
  }
`

export default CommentEmpty
