import React from "react"
import styled from "styled-components"
import { ReactComponent as ArrowIcon } from "../images/arrow-left.svg"

function ToListButton({ className, onClick }) {
  return (
    <Container className={className} onClick={onClick}>
      <ArrowIcon />
      목록으로
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 16px 0 8px;
  height: 40px;
  line-height: 42px;
  border: 1px solid #ebebeb;
  border-radius: 6px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  svg {
    margin-right: 4px;
  }
  &.mobile {
    display: none;
  }
  @media (max-width: 800px) {
    border: none !important;
    margin-bottom: 8px;
    &.mobile {
      display: inline-flex;
    }
    &.pc {
      display: none;
    }
  }
`

export default ToListButton
