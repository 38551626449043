import React from 'react'
import styled from 'styled-components'
import { signInWithGoogle } from './firebase.utils'
import { ReactComponent as GoogleImg } from '../images/google.svg'

function GoogleLoginButton() {
  return (
    <Button onClick={signInWithGoogle}>
      <GoogleImg />
    </Button>
  )
}

const Button = styled.div`
  width: 280px;
  height: 3rem;
  margin: 0 auto;
  background: #FAFAFA;
  border: 1px solid #EAEAEA;
  border-radius: 23px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default GoogleLoginButton
