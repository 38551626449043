import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { ReactComponent as CloseImg } from "../images/noti-close.svg"
import { ReactComponent as ArrowImg } from "../images/arrow-right.svg"
import { closeToast } from "../store/slices/layerSlice"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

const DURATION = 3000
export const ActionType = { CLOSE: "close", ARROW: "arrow" }

function Toast({ config = {} }) {
  const { iconSrc, text, pushLocation, actionText, actionType, duration } =
    config
  const timeout = useRef()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    timeout.current = setTimeout(() => {
      dispatch(closeToast())
    }, duration || DURATION)
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [dispatch, duration])

  const defaultAction = () => dispatch(closeToast())

  return (
    <Container>
      <Text>
        {iconSrc && <img src={iconSrc} alt="icon" />}
        <p>{text}</p>
      </Text>
      <ActionButton
        onClick={() =>
          pushLocation ? history.push(pushLocation) : defaultAction
        }
      >
        <p>{actionText}</p>
        <ActionIcon type={actionType} />
      </ActionButton>
    </Container>
  )
}

function ActionIcon({ type }) {
  switch (type) {
    case ActionType.ARROW:
      return <ArrowImg />
    default:
      return <CloseImg />
  }
}

const Container = styled.div`
  display: flex;
  position: fixed;
  width: 49%;
  min-width: 712px;
  height: 56px;
  left: 50%;
  bottom: 31px;
  padding: 0 12px;
  transform: translateX(-50%);
  justify-content: space-between;
  align-items: center;
  background: #faf9ff;
  border: 1px solid #d6d5ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  font-size: 14px;
  z-index: 10;
  @media (max-width: 800px) {
    width: calc(100vw - 48px);
    min-width: 0;
  }
`
const Text = styled.div`
  display: flex;
  align-items: center;
  > img {
    margin-right: 8px;
    width: 2rem;
    height: 2rem;
    background: rgba(85, 81, 255, 0.05);
    border-radius: 8px;
  }
`
const ActionButton = styled.div`
  cursor: pointer;
  font-weight: 500;
  color: #5551ff;
  display: flex;
  align-items: center;
  svg {
    margin-left: 12px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`

export default Toast
