import React from "react"
import { Link } from "react-router-dom"
import Gnb from "../common/Gnb"
import styled, { css } from "styled-components"
import { TYPE } from "../common/Gnb"
import { ReactComponent as ClearImg } from "../images/clear.svg"
import { ReactComponent as DoneImg } from "../images/done.svg"
import { detectLanguage } from "../common/utils"
import TagsInput from "react-tagsinput"
import "react-tagsinput/react-tagsinput.css"

function NewWriteView(props) {
  const {
    situation,
    suggestions,
    onClickSuggestion,
    textAreaRef,
    content,
    setContent,
    tagInputRef,
    tags,
    tagInput,
    setTagInput,
    handleTagsChange,
    receiver,
    setReceiver,
    language,
    setLanguage,
    onClickRegister,
    inputRef,
    input,
    setInput,
    onClickClear,
    isRegistering,
    isRegistered
  } = props

  if (isRegistered) {
    return (
      <div className="center">
        <Gnb type={TYPE.CLOSE} />
        <DoneView>
          <DoneImg className="mx-auto" />
          <p>
            소중한 템플릿을 공유해 주셔서 감사합니다.
            <br />
            앞으로도 많은 참여 부탁드려요!
          </p>
          <Link to="/templates">
            <button>템플릿 보러 가기</button>
          </Link>
        </DoneView>
      </div>
    )
  }

  const registerable = () => situation && content

  const onChangeContent = ({ target }) => {
    const { value } = target
    setContent(value)
    setLanguage(detectLanguage(value))
  }

  const onClickReceiver = ({ target }) => {
    if (target.id) {
      setReceiver(target.id)
    }
  }

  const onClickLanguage = ({ target }) => {
    if (target.id) {
      setLanguage(target.id)
    }
  }

  return (
    <div>
      <Gnb type={TYPE.CLOSE} />
      <Form>
        <SituationInput>
          <span>이렇게</span>
          <input
            ref={inputRef}
            type="text"
            value={input}
            onChange={({ target }) => setInput(target.value)}
            placeholder="상황을 입력해주세요"
          />
          {input && <ClearImg id="clear" onClick={onClickClear} />}
          {!situation && suggestions.length > 0 && (
            <Suggestions className="no-scrollbar">
              {suggestions.map(sug => (
                <div
                  key={sug.id}
                  onClick={() => onClickSuggestion(sug)}
                  className={`${sug.id === "add" ? "add" : ""}`}
                >
                  {sug.title}
                </div>
              ))}
            </Suggestions>
          )}
        </SituationInput>
        <Content highlight={true}>
          <p>템플릿 내용</p>
          <div id="textarea-container">
            <textarea
              ref={textAreaRef}
              className="no-scrollbar"
              placeholder={`내용을 입력해주세요.\n\n템플릿 품질 관리를 위해 아래 해당시 운영자에 의해 삭제될 수 있습니다.\n- 기존 템플릿과 동일한 내용\n- 욕설 및 비방, 비하\n- 무의미한 글자/단어 나열\n- 실명 등 개인정보를 포함한 내용`}
              value={content}
              onChange={onChangeContent}
            ></textarea>
            <TagsContainer>
              <TagsInput
                ref={tagInputRef}
                value={tags}
                inputProps={{
                  placeholder: "태그를 입력해주세요. (옵션)"
                }}
                onChange={handleTagsChange}
                onChangeInput={setTagInput}
                inputValue={tagInput}
              />
            </TagsContainer>
          </div>
          <p>누구에게 보낼만한 템플릿인가요?</p>
          <div className="radio-container" onClick={onClickReceiver}>
            <Radio id="colleague" selected={receiver === "colleague"}>
              직장, 동료
            </Radio>
            <Radio id="customer" selected={receiver === "customer"}>
              고객, 거래처
            </Radio>
            <Radio id="acq" selected={receiver === "acq"}>
              지인
            </Radio>
            <Radio id="prof" selected={receiver === "prof"}>
              교수님
            </Radio>
            <Radio id="parents" selected={receiver === "parents"}>
              부모님
            </Radio>
            <Radio id="friend" selected={receiver === "friend"}>
              친구
            </Radio>
            <Radio id="etc" selected={receiver === "etc"}>
              기타
            </Radio>
          </div>
          <p>언어는 자동 분류되지만 틀렸다면 다시 선택해주세요.</p>
          <div className="radio-container" onClick={onClickLanguage}>
            <Radio id="kor" selected={language === "kor"}>
              한글
            </Radio>
            <Radio id="eng" selected={language === "eng"}>
              영어
            </Radio>
            <Radio id="etc" selected={!["kor", "eng"].includes(language)}>
              기타
            </Radio>
          </div>
        </Content>
        <RegisterButton>
          <button
            onClick={onClickRegister}
            disabled={!registerable() || isRegistering}
          >
            {isRegistering ? "등록 중" : "작성 완료"}
          </button>
        </RegisterButton>
      </Form>
    </div>
  )
}

const Form = styled.div`
  position: absolute;
  top: calc(108.5px + var(--gnb-height));
  left: 50%;
  transform: translateX(-50%);
  width: 40rem;
  @media (max-width: 800px) {
    top: calc(32px + var(--top-padding));
    bottom: 0;
    min-height: 654px;
    width: 87%;
  }
`
const SituationInput = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  span {
    font-weight: 300;
    font-size: 24px;
  }
  input {
    flex: 1;
    border: none;
    box-sizing: border-box;
    background: #f6f6f6;
    border-radius: 10px;
    outline: none;
    padding: 1rem;
    -webkit-appearance: none;
    line-height: 24px;
    font-size: 24px;
    font-weight: 500;
    color: black;
    &::placeholder {
      color: #a9a9a9;
      font-size: 18px;
      font-weight: 400;
    }
  }
  #clear {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  @media (max-width: 800px) {
    span {
      font-size: 20px;
      flex-shrink: 0;
    }
    input {
      font-size: 20px;
      width: 60vw;
    }
  }
`
const Suggestions = styled.div`
  position: absolute;
  top: 56px;
  left: 70px;
  right: 0;
  margin-top: 9px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  max-height: 64vh;
  overflow-y: scroll;
  z-index: 10;
  > div {
    background-color: white;
    font-size: 1rem;
    line-height: 3rem;
    padding: 0 24px;
    &.add {
      color: #5551ff;
    }
    &:hover {
      background-color: #ebebeb;
      cursor: pointer;
    }
  }
  @media (max-width: 800px) {
    margin-top: 8px;
    /* max-height: 19rem; */
  }
`
const Content = styled.div`
  p {
    margin-top: 24px;
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 8px;
    span {
      color: red;
    }
  }
  #textarea-container {
    position: relative;
    border: 1px solid ${props => (props.highlight ? "#232A34" : "#EBEBEB")};
    border-radius: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 366px;
    padding: 24px 24px 126px;
  }
  textarea {
    border: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    outline: none;
    overflow: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    font-size: 14px;
    line-height: 20px;
    word-break: keep-all;
    &::placeholder {
      color: #c7c7c7;
    }
  }
  .radio-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  @media (max-width: 800px) {
    #textarea-container {
      padding: 16px;
    }
    textarea {
      font-size: 1rem;
    }
  }
`
const TagsContainer = styled.div`
  position: absolute;
  height: auto;
  left: 16px;
  right: 16px;
  bottom: 24px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 10px 12px;

  .react-tagsinput {
    border: none;
    width: 100%;
    padding: 0;
    background: none;
    font-size: 14px;

    > span {
      display: block;
      position: relative;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
      min-height: 16px;
    }

    .react-tagsinput-input {
      display: block;
      position: absolute;
      top: 0;
      align-items: center;
      width: 100%;
      padding: 0;
      margin: 0;
      font-size: 12px;
      color: #979797;
      ::placeholder {
        color: #979797;
      }
    }
    .react-tagsinput-tag {
      display: inline-flex;
      gap: 8px;
      align-items: center;
      margin: 30px 8px 0 0;
      padding: 10px 12px;
      border: none;
      font-size: 12px;
      background: #5551ff;
      border-radius: 4px;
      color: #ffffff;
    }
  }
`
const Radio = styled.span`
  display: inline-block;
  font-weight: 500;
  line-height: 40px;
  padding: 0 1rem;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    css`
      border-color: #5551ff;
      color: #5551ff;
      cursor: default;
    `}
`
const RegisterButton = styled.div`
  text-align: right;
  margin-top: 32px;
  margin-bottom: 157.5px;
  button {
    padding: 19px 32px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;
    background: #5551ff;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    &:disabled {
      background: #f6f6f6;
      color: #a9a9a9;
      cursor: default;
    }
  }
  @media (max-width: 800px) {
    margin-top: 2rem;
    text-align: center;
    button {
      margin-bottom: 40px;
      width: 100%;
    }
  }
`
const DoneView = styled.div`
  text-align: center;
  p {
    margin-top: 40px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #333333;
  }
  button {
    margin-top: 24px;
    height: 51px;
    line-height: 36px;
    border: 1px solid #232a34;
    border-radius: 30px;
    font-size: 1rem;
    color: #232a34;
    padding: 0 183.5px;
  }
  @media (max-width: 800px) {
    p {
      margin-top: 24px;
    }
    button {
      position: absolute;
      bottom: 40px;
      left: 24px;
      right: 24px;
      padding: 0;
    }
  }
`

export default NewWriteView
