import React from "react"
import styled, { css } from "styled-components"
import defaultImg from "../images/profile-default.svg"

function ProfilePicture({ src, size, onClick, className }) {
  return (
    <Container
      size={size || "100%"}
      clickable={onClick != null}
      className={className}
      onClick={onClick}
    >
      {!src && <img alt="profile" src={defaultImg} />}
      {src && <img alt="profile" src={src} />}
    </Container>
  )
}

const Container = styled.div`
  border-radius: 50%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  overflow: hidden;
  background-color: white;
  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
        `
      : ""}
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export default ProfilePicture
