import React from "react"
import styled from "styled-components"
import feature1 from "../images/feature1.png"
import feature2 from "../images/feature2.png"
import feature3 from "../images/feature3.png"

function Feature() {
  return (
    <FeatureContainer>
      <div>
        <FeatureImage>
          <img src={feature1} alt="feature" />
        </FeatureImage>
        <div>
          <FeatureDesc>
            <p>Feature 01</p>
            <p>기존 기능은 그대로</p>
            실제로 쓸 수 없는 올드한 템플릿은 이제 그만! <br />
            7천명의 사용자가 직접 공유한 템플릿을 사용해보세요. <br />
            복사해서 필요한 곳에 붙여 넣으면 끝-!
          </FeatureDesc>
        </div>
      </div>
      <div>
        <FeatureImage>
          <img src={feature2} alt="feature" />
        </FeatureImage>
        <div>
          <FeatureDesc>
            <p>Feature 02</p>
            <p>
              본문 검색 <span id="new">새로운 기능</span>
            </p>
            어느덧 수백 개의 상황이 생성되었어요. 상황을 고르기 힘들다면 원하는
            키워드로 본문 내용을 검색해 보세요!
          </FeatureDesc>
        </div>
      </div>
      <div>
        <FeatureImage>
          <img src={feature3} alt="feature" />
        </FeatureImage>
        <div>
          <FeatureDesc>
            <p>Feature 03</p>
            <p>
              다양한 필터와 정렬 <span id="new">새로운 기능</span>
            </p>
            짧은 연락인가요, 포멀한 이메일인가요? <br />
            원하는 템플릿을 찾는 데 도움이 될 수 있도록 연락 대상 필터와 길이순,
            최근 복사순 정렬이 추가되었어요!
          </FeatureDesc>
        </div>
      </div>
    </FeatureContainer>
  )
}

const FeatureContainer = styled.div`
  margin: 5rem auto 9rem;
  h2 {
    margin-bottom: 40px;
    font-family: "Space Grotesk", sans-serif;
    font-size: 46px;
    font-weight: 700;
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 784px;
    max-width: 90%;
    margin: 0 auto 4rem;
    &:nth-child(2n-1) {
      flex-direction: row-reverse;
    }
  }
  @media (max-width: 800px) {
    margin: 40.5px auto 56px;
    h2 {
      font-size: 32px;
    }
    > div {
      width: 87%;
      max-width: 327px;
      flex-direction: column !important;
    }
    .pc {
      display: none;
    }
  }
`
const FeatureImage = styled.div`
  width: 252px;
  height: 252px;
  max-width: 90vw;
  max-height: 90vw;
  > * {
    width: 100%;
    height: 100%;
  }
`
const FeatureDesc = styled.div`
  max-width: 424px;
  font-size: 1.125rem;
  text-align: left;
  color: #8e98a4;
  line-height: 133%;
  word-break: keep-all;

  > p:nth-of-type(1) {
    color: #5d5fef;
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    line-height: 100%;
  }

  > p:nth-of-type(2) {
    color: black;
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 24px;
    line-height: 100%;
    display: flex;
    align-items: center;

    #new {
      margin-left: 0.5rem;
      padding: 0 10.5px;
      display: inline-block;
      height: 30px;
      border-radius: 15px;
      background: #5551ff;
      color: #fff;
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 1.5rem;
    br {
      display: none;
    }
    > p:nth-of-type(2) {
      font-size: 32px;
    }
  }
`

export default Feature
